@charset "utf-8";

/*****사이트 리셋*****/
html,
body,
#container,
#body {
  min-width: 300px;
}
html,
body {
  overflow-x: hidden;
}
html {
  font-size: 100%; /* 1rem = 16px */

  -webkit-text-size-adjust: none;

  -moz-text-size-adjust: none;
}

/*input 사용자정의*/
input {
  font-family: "ONE-Mobile", Arial, sans-serif;
}
input::placeholder {
  font-family: "ONE-Mobile", Arial, sans-serif;
  color: #777;
  opacity: 1; /* Firefox */
}

input::placeholder {
  /* Edge 12 -18 */
  font-family: "ONE-Mobile", Arial, sans-serif;
  color: #777;
}

/* 공통 */
.pc_only {
  display: block;
}
.mo_only {
  display: none;
}

/* 반응형  css  base pc ---> mobile */
@media screen and (max-width: 1090px) {
  html {
    font-size: 90%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 80%;
  }
  /* 공통 */
  .pc_only {
    display: none;
  }
  .mo_only {
    display: block;
  }
}

@media screen and (max-width: 479px) {
  html {
    font-size: 70%;
  }
}

body {
  background-color: #fff;
  font-family: "ONE-Mobile", "Pretendard", Arial, sans-serif;
  font-weight: 400;
  color: #333;
  -webkit-text-size-adjust: none;
  word-break: keep-all;
}

/*****사이트 레이아웃*****/
.container {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
}

.m_br {
  /*모바일 줄바꿈*/
  display: none;
}

/*반응형  css */
@media screen and (max-width: 1500px) {
  section .container,
  footer .container {
    padding: 0 calc(10px + 1%);
  }
}
@media screen and (max-width: 599px) {
  .m_br {
    /*모바일 줄바꿈*/
    display: block !important;
  }
  .mo_none {
    display: none !important;
  }
}

/*반응형  css 끝*/

/*****사이트 레이아웃 끝*****/

/****타이틀 정의*****/

.h2.tit_simbol:after {
  width: 2.125rem;
  height: 2.125rem;
  background: #fcdfdf;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -12px;
  left: -16px;
  border-radius: 99px;
  z-index: 0;
}

/*타이틀 타입 01*/
h2.t_type01 {
  color: #222;
  position: relative;
  font-size: 2.25rem;
  width: 130px;
  line-height: 1.2;
  font-weight: 400;
  font-family: "ONE-Mobile-Title";
}
h2.t_type01 span {
  color: #e22626;
  position: relative;
  z-index: 1;
}

/*타이틀 타입 01 끝*/

/*///////**타이틀 정의 끝*****/

/*****헤더*****/
/* Header */
#header {
  position: fixed;
  width: 100%;
  transition: all 0.2s linear;
  top: 0;
  z-index: 99;
  overflow: hidden;
  height: 100px;
  text-align: center;
  border-bottom: 0px solid transparent;
}
#header:after {
  display: block;
  content: "";
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
  opacity: 0;
  z-index: 9999;
}

#header .header_in {
  position: relative;
  overflow: hidden;
  max-width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;
}
#header .header_in h1 {
  padding: 25px 0;
  float: left;
}
#header .header_in h1 a {
  display: block;
  width: 190px;
  height: 50px;
  background: url("../img/logo.png") no-repeat center center/101%;
  transition: all 0.3s linear;
}
#header .header_in .search {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translate(0, -50%);
  display: inline-block;
  width: 19px;
  height: 22px;
  /*background: url("../img/searchBtn.png") no-repeat center center/100%; */
}
#header .header_in .sitemapBtn {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: url("../img/menuBtn.png") center center no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
#header .header_in .btnMenu_m {
  display: none;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: url("../img/menuBtn.png") center center no-repeat;
  position: absolute;
  background-size: 25px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
#header .header_in .langBtn {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
}
#header .header_in .langBtn > a {
  position: relative;
  display: block;
  float: left;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.3pt;
  font-size: 0.925rem;
  margin-right: 14px;
  background: #3b63e3;
  padding: 5px 10px;
  border-radius: 4px;
  transition: 0.3s;
}
#header .header_in .langBtn > a:hover {
  background: #789eff;
}
#header .header_in .langBtn > a:last-child {
  margin: 0;
}
#header .header_in .langBtn > a.on {
  font-weight: 700;
}
#header .header_in .langBtn > a.on:after {
  display: block;
  content: "";
  width: 100%; /* background:#fff; */ /* margin-top:4px; */
}
#header .header_in .langBtn ul {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  visibility: hidden; /* padding:10px 0; */
  left: 0px;
  top: -270%;
  width: 100%;
  background: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 8px;
  box-shadow: 0 10px 12px 1px rgb(0 0 0 / 10%);
}
#header .header_in .langBtn ul.on {
  top: -50%;
  opacity: 1;
  visibility: visible;
  left: 0px;
}
#header .header_in .langBtn ul a {
  width: 100%;
  font-size: 1rem;
  display: block;
  padding: 4px 2px;
}
#header .header_in .langBtn ul a:hover {
  color: #000;
}
#header .header_in .langBtn ul li {
  transition: 0.3s;
}
#header .header_in .langBtn ul li:hover {
  background: #e3e6ee;
}

#header.on {
  height: 440px;
  transition: all 0.3s linear;
  background: #fff;
  box-shadow: rgb(0 0 0 / 12%) 0 3px 15px;
  border-color: #ddd;
  border-radius: 0 0 50px 50px;
}
#header.on:after {
  opacity: 1;
}
#header.on .header_in h1 a {
  background-image: url("../img/logo_up.png");
}
#header.on #gnb .menu > li > a {
  color: #333;
}
#header.on .header_in .search {
  /* background-image: url("../img/searchBtn_up.png"); */
}
#header.on .header_in .sitemapBtn {
  border-color: #ddd;
  background-image: url("../img/menuBtn_up.png");
}
#header.on .header_in .btnMenu_m {
  border-color: #ddd;
  background-image: url("../img/menuBtn_up.png");
}
#header.on .header_in .langBtn > a {
}
#header.on .header_in .langBtn > a.on {
  color: #0c0e3b;
  /* background: url(../img/icon_lang02.png) no-repeat 8px center; */
  background-size: 18px;
}
#header.on .header_in .header_in .langBtn > a.on:after {
  background: #1f25a3;
}

#header.fixed {
  transition: all 0.3s linear;
  background: rgb(255 255 255 / 98%);
  box-shadow: rgb(0 0 0 / 15%) 0 3px 15px;
  border-color: #ddd;
  height: 70px;
}
#header.fixed.on {
  height: 380px;
}
#header.fixed:after {
  opacity: 1;
  top: 70px;
}
#header.fixed .header_in h1 a {
  background-image: url("../img/logo_up.png");
}
#header.fixed .header_in h1 {
  padding: 10px 0;
}
#header.fixed #gnb .menu > li > a {
  color: #333;
  padding: 25px 50px 25px;
}
#header.fixed .header_in .search {
  /* background-image: url("../img/searchBtn_up.png"); */
}
#header.fixed .header_in .sitemapBtn {
  border-color: #ddd;
  background-image: url("../img/menuBtn_up.png");
}
#header.fixed .header_in .btnMenu_m {
  border-color: #ddd;
  background-image: url("../img/menuBtn_up.png");
}
#header.fixed .header_in .langBtn > a {
  color: #fff;
}
#header.fixed .header_in .langBtn > a.on {
  color: #0c0e3b;
  /* background: url(../img/icon_lang02.png) no-repeat 8px center; */
}
#header.fixed .header_in .langBtn > a.on:after {
  background: #1f25a3;
}

/* Header */

/* GNB */
#gnb {
  position: absolute;
  top: 0px;
  right: 17.5%;
  margin-right: 0px;
  transition: all 0.3s linear;
  z-index: 99999;
}
#gnb .menu {
}
#gnb .menu > li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}
#gnb .menu > li:first-child {
}
#gnb .menu > li:nth-child(4) {
}
#gnb .menu > li:last-child {
}
#gnb .menu > li:after {
  content: "";
  width: 0;
  height: 3px;
  background-color: #1f25a3;
  position: absolute;
  top: 57px;
  left: 0;
  transition: all 0.3s linear;
  z-index: 99999;
}
/* #gnb .menu > li.on:after {width:100%; transition: all 0.3s linear ease;} */
#gnb .menu > li > a {
  display: inline-block;
  padding: 40px 50px 40px;
  line-height: 1;
  font-size: 1.25rem;
  color: #fff;
  letter-spacing: 0px;
  position: relative;
  transition: all 0.3s linear;
  font-weight: 500; /* font-family: "GmarketSans"; */
}
#gnb .menu > li > a:after {
  display: block;
  content: "";
  width: 0px;
  height: 5px;
  background: #575eff;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s linear;
}
#gnb .menu > li.on > a {
}
#gnb .menu > li.on > a:after {
  opacity: 1;
  width: 100%;
}

#gnb .menu_depth.on {
  opacity: 1;
}

#gnb .menu_depth {
  padding: 35px 0 40px;
  opacity: 1;
  transition: all 0.1s linear;
}
#gnb .menu_depth ul > li {
  margin-bottom: 18px;
}
#gnb .menu_depth ul > li:last-child {
  margin-bottom: 0;
}
#gnb .menu_depth ul > li > a {
  display: inline-block;
  font-size: 1.063rem;
  font-weight: 300;
  letter-spacing: -0.3pt;
  line-height: 1.3;
  transition: all 0.2s linear;
  position: relative;
  font-weight: 300;
}
#gnb .menu_depth ul > li > a:hover {
  color: #2972fd;
  transition: all 0.2s linear;
  font-weight: 400;
}
#gnb .menu_depth ul > li > a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  width: 0;
  height: 0;
  margin: auto;
  border-bottom: 1px solid #497eff;
  transition: 0.2s;
}
#gnb .menu_depth ul > li > a:hover:after {
  width: 100%;
}
#header.fixed #gnb {
  /* top: 2px; */
}
/* GNB */

/* 슬라이드 메뉴 : 모바일 메뉴 */
#menuArea {
  display: none;
  position: fixed !important;
  top: 0;
  right: 0;
  width: 280px;
  z-index: 999999999999;
  height: 100%;
}
#menuArea .menuList {
  position: absolute;
  right: -280px;
  height: 100%;
  width: 280px;
  background: #fff;
  box-sizing: border-box;
}
#menuArea .menuList h1 {
  position: relative;
  padding: 15px 40px 5px 15px;
  box-sizing: border-box;
  overflow: hidden;
}
#menuArea .menuList h1 a {
  display: block;
  width: 12.75rem;
}
#menuArea .menuList h1 a img {
}
#menuArea .btnMenu_mClose {
  text-align: center;
  border: 0;
  background: #1f2125;
  border-radius: 8px;
  padding: 0;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  color: #fff;
  width: calc(100% - 40px);
  margin: 20px auto 0;
  display: block;
  line-height: 48px;
  font-family: "Outfit", sans-serif;
  letter-spacing: 0.3pt;
}

#menuArea .menuList > .list {
  background-color: #fff;
  width: 100%; /* padding:0 30px; */
  box-sizing: border-box;
}
#menuArea .menuList > .list > li {
  /* background:#fff; border-bottom:1px solid #ddd; */
}
#menuArea .menuList > .list > li:first-child > a {
  border-top: 1px solid #ddd;
}
#menuArea .menuList > .list > li > a {
  font-weight: 500;
  position: relative;
  display: block;
  padding: 20px 20px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  line-height: 1;
  color: #333;
  letter-spacing: -0.5pt;
  transition: all 0.3s ease-out;
}
#menuArea .menuList > .list > li.active a {
  color: #1f25a3;
  transition: all 0.3s ease-out;
}
#menuArea .menuList > .list > li > a:after {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 10px;
  height: 8px;
  /* background: url("../img/footBtn.png") center center/100% no-repeat; */
  background-size: 100%;
}
#menuArea .menuList > .list > li.active > a:after {
  /* background-image: url("../img/footBtn_up.png"); */
}

#menuArea .menuList > .list .sMenu {
  background-color: #f0f0f0;
  position: relative;
  width: 100%;
  display: none;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}
#menuArea .menuList > .list .sMenu > li {
  margin: 0 0 15px;
}
#menuArea .menuList > .list .sMenu > li .depth_txt {
  display: block;
  font-size: 13px;
  color: #333;
  margin: 0 0 5px;
  letter-spacing: -0.3pt;
  font-weight: bold;
}
#menuArea .menuList > .list .sMenu > li > a {
  font-weight: 400;
  display: block;
  border: none;
  font-size: 15px;
  letter-spacing: -0.5pt;
  color: #666;
  transition: 0.3s linear ease;
  line-height: 1;
  margin: 0 0 7px;
}
#menuArea .menuList > .list .sMenu > li:last-child {
  margin: 0 0 0;
}
#menuArea .menuList > .list .sMenu > li > a:hover {
  color: #1f25a3;
  transition: all 0.3s linear ease;
}
#menuArea .menuList > .list .sMenu li:first-child a {
  padding-top: 0;
}
#menuArea .menuList > .list .sMenu li:last-child {
  margin: 0;
}
#menuArea .menuList > .list .sMenu li:last-child a {
  margin: 0;
}

#grayLayer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
}
#grayLayer > a {
  display: block;
  width: 100%;
  height: 100%;
}
/* 슬라이드 메뉴 : 모바일 메뉴 */

/* 헤더 반응형 */
/*******************************************************************************
	@media ~1600px
*******************************************************************************/
@media all and (max-width: 1600px) {
  /* Header */
  #header .header_in {
    width: 94%;
    padding: 0;
  }
  #header .header_in .langBtn {
    right: 0px;
  }
  /* Header */

  /* GNB */
  #gnb {
    /* margin-left: -120px; */ /* top: 41px; */
    right: 12%;
  }
  #gnb .menu > li {
  }
  #gnb .menu > li:nth-child(4) {
  }
  /* GNB */
}

/*******************************************************************************
	@media ~1300px
*******************************************************************************/
@media all and (max-width: 1300px) {
  /* Header */
  #header.on {
    height: 380px;
  }
  #header .header_in {
    /* width:94%; padding:0; */
  }
  /* Header */

  /* logo */
  #header .header_in h1 a {
    width: 170px;
  }
  /* logo */
  /* GNB */
  #gnb {
    margin-left: -80px; /* top: 41px; */
  }
  #gnb .menu > li {
    /* width:100px; */
  }
  #gnb .menu > li:nth-child(4) {
    /* width: 120px; */
  }
  #gnb .menu > li > a {
    padding: 41px 38px 41px;
    font-size: 1.15rem;
  }
  #gnb .menu_depth ul > li > a {
    font-size: 1rem;
  }
  #gnb .menu_depth ul > li {
    margin-bottom: 10px;
  }
  #header.fixed #gnb .menu > li > a {
    padding: 22px 15px 26px;
  }
  /* GNB */
}

/*******************************************************************************
	@media ~1024px
*******************************************************************************/
@media all and (max-width: 1024px) {
  /* Header */
  #header .header_in .sitemapBtn {
    display: none;
  }
  #header .header_in .btnMenu_m {
    display: block;
  }
  #header .header_in .langBtn {
    right: 65px;
  }
  /* Header */

  /* GNB */
  #gnb {
    display: none;
  }
  /* GNB */
}

/*******************************************************************************
	@media 769~980px
*******************************************************************************/
@media all and (max-width: 980px) {
  /* Header */
  #header {
    height: auto;
  }
  #header .header_in h1 {
    padding: 20px 0;
  }
  #header .header_in h1 a {
  }

  #header .header_in .search {
  }
  #header .header_in .sitemapBtn {
    display: none;
  }
  #header .header_in .btnMenu_m {
    display: block;
  }
  /* Header */

  /* GNB */
  #gnb {
    display: none;
  }
  /* GNB */

  .page_num {
    padding-top: 30px;
  }
}

/*******************************************************************************
	@media 681 ~ 768px
*******************************************************************************/
@media all and (max-width: 768px) {
  .show768 {
    display: block;
  }
  .none768 {
    display: none;
  }
  #header.fixed {
    height: 48px;
  }

  #header .header_in .langBtn > a {
    background-size: 12px !important;
  }
}

/*******************************************************************************
	@media 481~680px
*******************************************************************************/
@media all and (max-width: 680px) {
  /* Header */
  #header {
    height: auto;
  }
  #header .header_in h1 {
  }
  #header .header_in h1 a {
    width: 140px;
    height: 28px;
  }

  #header .header_in .search {
    right: 47px;
    width: 15px;
    height: 17px;
  }
  #header .header_in .btnMenu_m {
    height: 40px;
    width: 40px;
  }
  #header .header_in .langBtn {
    right: 50px;
  }
  .langBtn a {
    margin-right: 8px;
  }
  .langBtn a.on:after {
    margin-top: 2px;
  }
  /* Header */
}

/*******************************************************************************
	@media 361~480px
*******************************************************************************/
@media all and (max-width: 480px) {
}

/*******************************************************************************
	@media ~420px
*******************************************************************************/
@media all and (max-width: 420px) {
  .show420 {
    display: block;
  }
  .none420 {
    display: none;
  }
}
/* 헤더 반응형 끝 */

/*****헤더 끝*****/

/*****메인 비쥬얼*****/
#visual .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 1;
}
#visual .bg-video .bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
#visual .bg-video:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 23, 40, 0) url(../img/main_dot.png) repeat;
  z-index: 1;
  opacity: 0.25;
}

#visual {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1; /*  opacity:0; background-color:#333; */
  font-family: "play", "ONE-Mobile";
}

#visual .slick.slider01 {
  position: relative;
  display: block;
}
#visual .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
}
#visual .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
#visual .slick-initialized .slick-slide {
  display: block;
}
#visual .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

#visual .sub_imgbox li {
  width: 100%;
  height: 100%;
}

#visual .section .v_img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: #131427;
  background-size: cover;
  background-position: center;
  border: 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
#visual .sub_imgbox li.action .v_img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
}
#visual .section.cover01 .container {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#visual .section.cover01 .v_img {
  background-image: url("../img/main_visual01.jpg");
}
#visual .section.cover02 .v_img {
 /* background-image: url("../img/main_visual02.jpg"); */
}
#visual .section.cover03 .v_img {
 /* background-image: url("../img/main_visual03.jpg"); */
}

#visual .sub_imgbox {
  height: 100%;
}
#visual .section {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  border: 0;
}
#visual .sub_imgbox .slick-list,
#visual .sub_imgbox .slick-track {
  height: 100%;
}

#visual .controller {
  position: absolute;
  bottom: 29px;
  left: 50%;
  margin-left: 90px;
  z-index: 9001;
}
#visual .controller .btn-play {
  position: relative;
  cursor: pointer;
  display: none;
  /* background: url("../img/play.png") center no-repeat; */
  text-indent: -222222222px;
  border: none;
  width: 10px;
  height: 16px;
}
#visual .controller .btn-stop {
  position: relative;
  cursor: pointer;
  width: 10px;
  /* background: url("../img/stop.png") center no-repeat; */
  height: 16px;
  text-indent: -222222222px;
}

/* Page */
#visual .slick-arrow {
  font-size: 0;
  line-height: 0;
  padding: 0;
  position: absolute;
  display: block;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  width: 9px;
  height: 15px;
  /* background: url("../img/v_prev.png") center center/100% no-repeat; */
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -65px;
  z-index: 9999;
  cursor: pointer;
}
#visual .slick-next {
  right: 50%;
  left: auto;
  margin: 0 -65px 0 0;
  /* background-image: url("../img/v_next.png"); */
}

/* #visual .slick-page{position: absolute; left:50%; transform:translate(-50%, 0); bottom:30px; z-index:9001; text-align:center; box-sizing:border-box; line-height:1}
#visual .slick-page li {font-family: 'Outfit', sans-serif; font-size:0.882rem; color:#fff; line-height:1;}
#visual .slick-page li.cur{display:inline-block; font-weight:700}
#visual .slick-page li.and{display:inline-block; padding:0 12px; background:url('../img/page.png') center center no-repeat; width:8px; height:10px;}
#visual .slick-page li.tot{display:inline-block;} */

#visual .slider-paging-number {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 30px;
  z-index: 9001;
  text-align: center;
  box-sizing: border-box;
  line-height: 1;
}
#visual .slider-paging-number li {
  font-family: "Outfit", sans-serif;
  font-size: 0.882rem;
  color: #fff;
  line-height: 1;
  display: none;
  font-weight: 400;
}
#visual .slider-paging-number li button {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  font-size: 0.882rem;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  background: none;
  border: none;
}
#visual .slider-paging-number li button:after {
  display: inline-block;
  content: "";
  padding: 0 12px;
  /* background: url("../img/page.png") center center no-repeat; */
  width: 8px;
  height: 10px;
}
#visual .slider-paging-number li.slick-active {
  display: block;
}

#visual .v_txt {
  text-align: center;
  z-index: 1000;
  box-sizing: border-box;
  margin: 0 auto;
  position: absolute;
  transform: translateY(-80%);
  z-index: 3;
  width: auto;
  left: 0;
  right: 0;
  top: 50%;
  padding: 0px 0 0 0;
}
#visual .v_txt h3 {
  position: relative;
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  line-height: 1.3;
  margin: 0 0 4.5rem 0;
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  top: 50px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
#visual .v_txt .tg01 {
  margin: 0px 0 0 0;
}
#visual .v_txt p {
  position: relative;
  font-size: 1.625rem;
  letter-spacing: -0.5pt;
  color: white;
  background: linear-gradient(to bottom, #ffffff, #babaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  opacity: 0;
  top: 50px;
  margin: 0 0 0px 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

#visual .v_txt span {
  position: relative;
  line-height: 1.6;
  font-size: 1.15rem;
  letter-spacing: 0;
  color: #fff;
  font-weight: 200;
  opacity: 0;
  top: 50px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
#visual .v_txt span strong {
  font-weight: 600;
}

#visual .v_txt .more {
  position: relative;
  margin-top: 100px;
  opacity: 0;
  top: 50px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#visual .v_img01 {
  box-sizing: border-box;
  margin: 0 auto;
  position: absolute;
  transform: translateY(-13%);
  z-index: 2;
  width: auto;
  left: 0;
  right: 0;
  top: 50%;
  padding: 0 3% 0px 50%;
  text-align: right;
  display: flex;
  justify-content: center;
}
#visual .v_img01 {
  position: relative;
  opacity: 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

#visual .sub_imgbox li.action .v_txt h3 {
  top: 0;
  opacity: 1;
}
#visual .sub_imgbox li.action .v_txt p {
  top: 0;
  opacity: 1;
}
#visual .sub_imgbox li.action .v_txt span {
  top: 0;
  opacity: 1;
}
#visual .sub_imgbox li.action .v_txt .more {
  top: 0;
  opacity: 1;
}
#visual .sub_imgbox li.action .v_img01 {
  opacity: 1;
  transform: translateY(-40%);
}

.m_tit_con {
  margin-bottom: 60px;
}
.m_tit {
  position: relative;
  line-height: 1;
  font-weight: 700;
  color: #333;
  font-family: "Outfit", sans-serif;
  margin: 0 0 20px;
  font-size: 3.176rem;
  letter-spacing: 0;
}
.m_stit {
  position: relative;
  line-height: 1;
  font-size: 1.765rem;
  letter-spacing: -0.5pt;
  font-weight: 700;
  color: #fff;
  margin-bottom: 18px;
}
.m_txt {
  letter-spacing: -0.3pt;
  font-size: 1rem;
  color: #666;
}

.ani_effect {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: all 0.5s linear;
}
.ani_effect.action {
  top: 0;
  opacity: 1;
  transition: all 0.5s linear;
}

.ani_effect2 {
  position: relative;
  top: 0;
  opacity: 0;
  transition: all 0.5s linear;
}
.ani_effect2.action {
  top: 0;
  opacity: 1;
  transition: all 0.5s linear;
}

.delay1 {
  transition-delay: 0.4s !important;
}
.delay2 {
  transition-delay: 0.7s !important;
}
.delay3 {
  transition-delay: 1s !important;
}
.delay4 {
  transition-delay: 1.3s !important;
}

/*메인비쥬얼 퀵메뉴*/
.main_v_quick {
  position: absolute;
  z-index: 999;
  width: 100%;
  bottom: 8%;
  display: flex;
  justify-content: center;
}
ul.quick_wg {
  width: 100%;
  max-width: 880px;
  display: flex;
  justify-content: space-between;
}
ul.quick_wg li {
  width: 21%;
}
ul.quick_wg li .cbw {
  padding: 0 0 100%;
  position: relative;
  text-align: center;
}
ul.quick_wg li .cbw .cbox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  flex-direction: column;
  border: 2px solid rgb(255 255 255 / 50%);
  row-gap: 10%;
  transition: 0.3s;
}
ul.quick_wg li .cbw .cbox:hover {
  background: rgb(120 158 255 / 40%);
}
ul.quick_wg li .cbw .cbox .ig01 {
  width: 100%;
  max-width: 60px;
}
ul.quick_wg li .cbw .cbox .ig01 img {
}
ul.quick_wg li .cbw .cbox .tg01 {
}
ul.quick_wg li .cbw .cbox .tg01 .t01 {
  color: #fff;
  font-size: 1.25rem;
}
/* ////메인비쥬얼 퀵메뉴 끝*/

/* 바로가기 버튼 (GENERAL BUTTON STYLING) ~*/

/* 버튼 타입 01 */
.btn1a1[class] {
  position: relative;
  height: auto;
  margin: 0 0.525rem;
  padding: 0.65em 1.875em;
  border: 0px solid #145fe9;
  border-radius: 10px;
  background: #081125;
  color: #fff;
  font-weight: normal;
  font-size: 1.375em;
  transition: 0.3s;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn1a1::before,
.btn1a1::after {
  content: "";
  z-index: -1;
  position: absolute;
  background: #ffffff;
  transition: 0.3s;
  border-radius: 10px;
}
.btn1a1:hover {
  color: #2664ef !important;
  background: rgb(20 95 233);
}

/* 버튼 타입 02 */
.btn1a2[class] {
  display: table;
  position: relative;
  height: auto;
  margin: 0 auto;
  padding: 0.65em 1.875em;
  border: 0px solid #145fe9;
  border-radius: 10px;
  background: #2664ef;
  color: #fff;
  font-weight: normal;
  font-size: 1.25em;
  transition: 0.3s;
  text-decoration: none;
  text-align: center;
}
.btn1a2::before,
.btn1a2::after {
  content: "";
  z-index: -1;
  position: absolute;
  background: #ffffff;
  transition: 0.3s;
  border-radius: 10px;
}
.btn1a2:hover {
  color: #2664ef;
  background: #fff !important;
  box-shadow: 0 15px 15px rgb(0 0 0 / 8%);
}

/* 버튼 타입 03 */
.btn1a3[class] {
  display: inline-flex;
  position: relative;
  height: auto;
  margin: 0 auto;
  padding: 0.85em 1.875em;
  border: 0px solid #145fe9;
  border-radius: 10px;
  background: #2664ef;
  color: #fff;
  font-weight: normal;
  font-size: 1.3em;
  transition: 0.3s;
  text-decoration: none;
  text-align: center;
  flex-direction: row;
  align-items: center;
}
.btn1a3 img {
  opacity: 0;
  margin-left: 0.3em;
  transition: 0.4s;
  position: absolute;
  right: 0;
  transform: translate(-180%, 0px);
}
.btn1a3::before,
.btn1a3::after {
  content: "";
  z-index: -1;
  position: absolute;
  background: #ffffff;
  transition: 0.3s;
  border-radius: 10px;
}
.btn1a3:hover {
  color: #2664ef;
  background: #fff !important;
  box-shadow: 0 10px 15px rgb(0 0 0 / 8%);
  padding: 0.85em 2.75em 0.85em 1.875em;
}
.btn1a3:hover img {
  opacity: 1;
}

/* 버튼 타입 04 */
.btn1a4[class] {
  display: inline-flex;
  position: relative;
  height: auto;
  margin: 0 auto;
  padding: 0.9em 2.5em 0.9em 1em;
  border: 0px solid #145fe9;
  border-radius: 10px;
  color: #fff;
  font-weight: normal;
  font-size: 1.3em;
  transition: 0.3s;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  /* background: url(../img/company/down_icon.png) no-repeat #2664ef 85% 50%; */
}
.btn1a4 img {
  opacity: 0;
  margin-left: 0.6em;
  transition: 0.4s;
  position: relative;
}
.btn1a4::before,
.btn1a4::after {
  content: "";
  z-index: -1;
  position: absolute;
  background: #ffffff;
  transition: 0.3s;
  border-radius: 10px;
}
.btn1a4:hover {
  color: #2664ef;
  /* background: url(../img/company/down_icon_on.png) no-repeat #fff 85% 55% !important; */
  box-shadow: 0 10px 15px rgb(0 0 0 / 8%);
  padding: 0.9em 2.5em 0.9em 1em;
}
.btn1a4:hover img {
  opacity: 1;
}

/* 버튼 컬러 */
.white {
  background: #fff !important;
}
.red {
  background: #ff0000 !important;
}
.blue {
  background: #0000ff !important;
}
.navi {
  background-color: #0f182e !important;
}
.black {
  background: #111 !important;
}

/*  */
.btn-1::after {
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
}
.btn-1:hover:after {
  height: 100%;
}
/*  */
.btn-2::after {
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
}
.btn-2:hover:after {
  width: 100%;
}
/*  */
.btn-3::after {
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
}
.btn-3:hover:after {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/*  */
.btn-4::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.btn-4::after {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2ecc71;
}
.btn-4:hover:after {
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
}
/*  */
.btn-5 {
  overflow: hidden;
}
.btn-5::after {
  left: -35%;
  top: 0;
  width: 0;
  height: 100%;
  transform: skew(50deg);
  transition-duration: 0.6s;
  transform-origin: top left;
}
.btn-5:hover:after {
  width: 135%;
  height: 100%;
}
/*****메인 비쥬얼 끝*****/

/*****메인비주얼 반응형*****/
@media all and (max-width: 1600px) {
  #visual .v_txt {
    /* width: 95%; */ /* padding: 0px 45% 0 0; */
  }
  #visual .v_txt h3 {
    font-size: 4.65rem;
    margin: 0 0 4rem 0;
  }
  #visual .v_txt span {
    /* font-size: 1.125rem; */
  }
  #visual .v_txt p {
  }
}

@media all and (max-width: 1280px) {
  #visual .v_txt {
    /* width: 95%; */
  }
  #visual .sub_imgbox li.action .v_img01 {
    transform: translateY(-35%);
  }
  #visual .sub_imgbox li.action .v_img01 img {
    max-width: 70%;
  }
}

@media all and (max-width: 980px) {
  #visual {
  }
  #visual .v_txt {
    /* width: 95%; */
  }
  #visual .v_txt h3 {
    font-size: 4.25rem;
    margin: 0 0 3rem 0;
  }
  #visual .v_txt p {
    font-size: 1.5rem;
  }
  #visual .v_txt span {
    font-size: 1.1rem;
  }
  #visual .v_txt .more {
    margin-top: 70px;
  }
  .btn1a1[class] {
    padding: 0.65em 1.25em;
    width: 150px;
  }

  /*메인비쥬얼 퀵메뉴*/
  ul.quick_wg {
    max-width: 750px;
  }
  ul.quick_wg li .cbw .cbox .ig01 {
    max-width: 45px;
  }

  /* ////메인비쥬얼 퀵메뉴 끝*/
}

@media all and (max-width: 680px) {
  #visual .bg-video {
    display: none;
  }
  #visual {
  }
  #visual .v_txt {
    padding: 0 0% 0 0;
    text-align: center;
    transform: translateY(-85%);
  }
  #visual .v_txt h3 {
    font-size: 3.25rem;
    margin-bottom: 25px;
  }
  #visual .v_txt p {
    font-size: 1.25rem;
  }
  #visual .v_txt span {
    font-size: 1rem;
  }
  #visual .v_txt .more {
    margin-top: 40px;
  }
  #visual .sub_imgbox li.action .v_img01 {
    padding: 0 35% 0px 35%;
    text-align: center;
    top: 22vw;
    transform: translateY(0);
  }

  #visual .slick-arrow {
    width: 7px;
    height: 12px;
    margin-left: -50px;
  }
  #visual .slick-next {
    margin: 0 -50px 0 0;
  }

  #visual .controller {
    margin-left: 67px;
    bottom: 30px;
  }
  #visual .controller .btn-stop {
    background-size: 8px;
    height: 12px;
    width: 8px;
  }
  #visual .controller .btn-play {
    background-size: 8px;
    height: 12px;
    width: 8px;
  }

  #visual .slider-paging-number {
  }
  #visual .slider-paging-number li {
  }
  #visual .slider-paging-number li button {
  }
  #visual .slider-paging-number li button:after {
    width: 6px;
    height: 8px;
  }

  /*메인비쥬얼 퀵메뉴*/
  ul.quick_wg {
    width: 95%;
  }
  ul.quick_wg li {
    width: 23%;
  }
  ul.quick_wg li .cbw .cbox .ig01 {
    max-width: 30px;
  }
  ul.quick_wg li .cbw .cbox .tg01 .t01 {
    font-size: 1rem;
  }

  /* ////메인비쥬얼 퀵메뉴 끝*/
}
@media all and (max-width: 580px) {
  #visual .v_txt {
    /* transform: translateY(0%); */
  }
  #visual .v_txt .more {
    justify-content: center;
    margin-top: 60px;
  }
}

@media all and (max-width: 480px) {
  #visual .v_txt {
    padding: 0 0% 0 0;
  }
  #visual .v_txt h3 br {
    display: none;
  }
  #visual .v_txt span br {
    display: none;
  }
  .btn1a1[class] {
    padding: 0.55em 1.375em;
  }
}

/*****메인비주얼 반응형 끝*****/

/****메인 섹션타이틀*****/

/*섹션타이틀 타입 01*/

.sec01tg01 {
  font-family: "play", "ONE-Mobile";
  max-width: 1500px;
  margin: 0px auto;
}
.sec01tg01 .t01 {
  font-size: 3.75rem;

  line-height: 0.975;

  font-weight: 600;

  color: #111;
}
.sec01tg01 .t01 span {
  font-size: 5rem;

  color: #446ffa;
}

.sec01tg01 .t02 {
  font-size: 1.125rem;

  margin-top: 1.75rem;

  line-height: 1.5;

  color: #444444;
}
/*메인 섹션타이틀 타입 01 끝*/

/*섹션타이틀 타입 02*/

.sec02tg01 {
  font-family: "play", "ONE-Mobile";
}
.sec02tg01 .t01 {
  font-size: 3.75rem;

  line-height: 0.975;

  font-weight: 600;

  color: #fff;
}
.sec02tg01 .t01 span {
  font-size: 5rem;

  color: #446ffa;
}

.sec02tg01 .t02 {
  font-size: 1.125rem;

  margin-top: 1.75rem;

  line-height: 1.5;

  color: #ccc;
}
/*메인 섹션타이틀 타입 02 끝*/

/*섹션타이틀 타입 03*/

.msection_tit_wrap {
  font-family: "play", "ONE-Mobile";

  text-align: center;
}
.msection_tit_wrap h2 {
  font-size: 3.125rem;

  line-height: 0.975;

  font-weight: 600;

  color: #222;
}
.msection_tit_wrap p {
  font-size: 1.25rem;

  color: #555;

  margin: 1.425rem 0 0;
}

/*메인 섹션타이틀 타입 03 끝*/

/* --------섹션01 사업영역-------- */

#sec01 {
  padding: 6.275rem 0 6.275rem;
}

.business_wrap {
}
.business_wrap .wg01 {
  display: flex;
  gap: 4%;
}
.business_wrap .wg01 .w01 {
  width: 100%;
  position: absolute;
}
.business_wrap .wg01 .w02 {
  width: 64%;
  overflow: hidden;
  margin: 0 0 0 auto;
}

.slide_type01 {
}

.slide_type01 .swiper-slide {
  width: 345px !important;
  overflow: hidden;
}
.slide_type01 span.bg {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 129.972%;
  transition: 0.3s;
}
.slide_type01 .img-cover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.slide_type01 .inner-box {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10% 8%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  font-family: "play", "ONE-Mobile";
}
.slide_type01 .inner-box .tit {
  display: inline-block;
  font-size: 1.875rem;
  letter-spacing: -0.01em;
  color: #fff;
  font-weight: 700;
  font-family: var(--font-family2);
  margin-bottom: 2%;
  transition: 0.3s;
}
.slide_type01 .inner-box .sub-tit {
  display: block;
  font-size: 1.125rem;
  letter-spacing: -0.045em;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  line-height: 1.556em;
  transition: 0.3s;
}
.slide_type01 .inner-box .bottom {
  opacity: 0;
  transition: 0.4s;
}
.slide_type01 .inner-box i {
  position: absolute;
  right: 45px;
  bottom: 65px;
  color: #fff;
  font-size: 20px;
  opacity: 0;
  transition: var(--transition-custom);
}
.slide_type01 .inner-box .detail-list li {
  font-size: 1.125rem;
  letter-spacing: -0.045em;
  color: #fff;
  font-weight: 600;
  line-height: 1.75em;
}
.slide_type01 a:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #026bf8;
  opacity: 0;
  z-index: 1;
  transition: 0.6s;
}
.slide_type01 .item:hover a:before {
  opacity: 1;
}
.slide_type01 .item:hover span.bg {
  transform: scale(1.1) rotate(0.002deg);
}
.slide_type01 .item:hover .sub-tit {
  color: rgba(255, 255, 255, 0.7);
}
.slide_type01 .item:hover .bottom {
  opacity: 1;
}
.slide_type01 .item:hover .inner-box i {
  opacity: 1;
}

/*네비게이션 화살표*/
.business_wrap .ar_w01 {
  position: relative;
  max-width: 1500px;
  margin: 4rem auto 0px;
  display: flex;
  gap: 10px;
}
.business_wrap .swiper-button-prev,
.business_wrap .swiper-button-next {
  position: relative;
  top: 0;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 10;
  border-radius: 10px;
  cursor: pointer;
  background: #ffffff;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  box-shadow: 0 10px 15px rgb(0 0 0 / 12%);
  transition: 0.3s;
}
.business_wrap .swiper-button-prev:hover,
.business_wrap .swiper-button-next:hover {
  background-color: #0a0b20;

  border: 1px solid #0a0b20;
}
.business_wrap .swiper-button-prev.swiper-button-disabled,
.business_wrap .swiper-button-next.swiper-button-disabled {
  opacity: 1;
  background-color: #b5b5b5;
  cursor: auto;
  pointer-events: none;
}
.business_wrap .swiper-button-prev,
.business_wrap .swiper-container-rtl .swiper-button-next {
  background-image: url("../img/weui_arrow-outlined-l.svg");
  background-size: auto;
}
.business_wrap .swiper-button-next,
.business_wrap .swiper-container-rtl .swiper-button-prev {
  background-image: url("../img/weui_arrow-outlined-r.svg");
  background-size: auto;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0px !important;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width: 1600px) {
  .business_wrap .wg01 .w01 {
    padding: 0 1.2%;
  }
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1320px) {
  .business_wrap .wg01 {
  }
  .business_wrap .wg01 .w01 {
    position: relative;
    width: auto;
  }
  .business_wrap .wg01 .w02 {
  }
}

@media screen and (max-width: 1090px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .business_wrap .wg01 {
    flex-direction: column;
  }
  .business_wrap .wg01 .w02 {
    width: 100%;
    margin: 3rem 0 0 auto;
  }
  .business_wrap .ar_w01 {
    margin: 1.45rem auto 0px;
  }
  .business_wrap .swiper-button-prev,
  .business_wrap .swiper-button-next {
  }
  .slide_type01 .swiper-slide {
    width: 220px !important;
  }
  .slide_type01 .inner-box .tit {
    font-size: 1.5rem;
  }
  .slide_type01 .inner-box .sub-tit {
    font-size: 1rem;
  }
  .slide_type01 .inner-box .detail-list li {
    font-size: 1rem;
  }
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}

/* --------/////섹션01 사업영역 끝-------- */

/* --------섹션02 솔루션-------- */

#sec02 {
  padding: 6.275rem 0 6.275rem;
  background: url(../img/m_solution_bg.jpg) center no-repeat;
  background-size: cover;
}

.solution_wrap {
}
.solution_wrap .wg01 {
  display: flex;
}
.solution_wrap .wg01 .w01 {
  width: 30%;
  position: relative;
}
.solution_wrap .wg01 .w02 {
  width: calc(100% - 30%);
}

.list-box {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}
.list-box .b-item {
  position: relative;
  width: calc((100% - 59px) / 3);
  margin-right: 2%;
  background: rgba(7, 56, 123, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2% 2.2%;
  box-sizing: border-box;
  min-height: 240px;
  max-height: 240px;
  transition: 0.4s;
}
.list-box .b-item:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #026bf8;
  opacity: 0;
  transition: 0.6s;
  z-index: -1;
}
.list-box .b-item a {
  width: 100%;
  height: 100%;
}
.list-box .b-item:last-child {
  margin-right: 0;
  margin-bottom: 19px;
}
.list-box .b-item:nth-child(3) {
  margin-right: 0px;
}
.list-box .b-item:nth-child(4) {
  margin-bottom: 19px;
}
.list-box .item-tit {
  font-family: "play", "ONE-Mobile";
}
.list-box .item-txt {
  font-size: 1rem;
  letter-spacing: -0.05em;
  color: #a2c6f5;
  letter-spacing: -0.05em;
  line-height: 1.5em;
  opacity: 0;
  transition: var(--transition-custom);
}
.list-box .num {
  font-size: 1.25rem;
  letter-spacing: -0.01em;
  color: #519bff;
  line-height: 1.75em;
  font-weight: 700;
  transition: all 0.3s;
}
.list-box .title {
  display: block;
  font-size: 1.5rem;
  letter-spacing: -0.01em;
  color: #fff;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 0.875rem;
}
.list-box .icon-box {
  width: 48px;
  position: absolute;
  right: 40px;
  bottom: 30px;
}
.list-box .icon-box span {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 100%;
}
.list-box .icon-box img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
/* .list-box .b-item:hover {background: var(--main-color);} */
.list-box .b-item:hover:before {
  opacity: 1;
}
.list-box .b-item:hover .item-txt {
  opacity: 1;
}
.list-box .b-item:hover .num {
  color: rgba(255, 255, 255, 0.6);
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1320px) {
  .solution_wrap .wg01 {
    flex-direction: column;
    row-gap: 1.45rem;
  }
  .solution_wrap .wg01 .w01 {
    width: 100%;
  }
  .solution_wrap .wg01 .w02 {
    width: 100%;
  }
}

@media screen and (max-width: 1090px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .list-box {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .list-box .b-item {
    width: 100%;
    margin-right: 0;
    min-height: 150px;
    padding: 2% 4%;
    margin-bottom: 10px;
  }
  .list-box .b-item:nth-child(1) {
    order: 2;
  }
  .list-box .b-item:nth-child(2) {
    order: 3;
  }
  .list-box .b-item:nth-child(3) {
    order: 4;
    margin-bottom: 0;
  }
  .list-box .b-item:nth-child(4) {
    order: 0;
    margin-bottom: 10px;
  }
  .list-box .b-item:last-child {
    order: 1;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}

/* --------/////섹션02 솔루션 끝-------- */

/* --------섹션03 다이나시스템 소개-------- */

.dynasystem {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  /*height: 86rem*/
}

.dynasystem .item {
  width: 50%;
}

/* 슬라이드_nav */

.dynasystem .item.slide_nav {
  padding: 9rem 3rem 5rem;
  background: #09297e;
}
.dynasystem .item.slide_nav .tit_wrap {
  text-align: center;
}

.dynasystem .item.slide_nav .tit_wrap > *:first-child {
  color: #222;
  font-size: 2.375rem;
  font-weight: 800;
}

.dynasystem .item.slide_nav .tit_wrap > * > span {
  font-size: 2.75rem;
}

.dynasystem .item.slide_nav .tit_wrap > *:first-child + p {
  margin-top: 2rem;
  color: #595959;
  line-height: 2;
  word-break: keep-all;
}

.dynasystem .item.slide_nav .tit_wrap > *:first-child {
  color: #fff;
  letter-spacing: -0.05rem;
}

.dynasystem .item.slide_nav .tit_wrap > *:first-child + p {
  color: #d0d6e0;
  font-size: 1.25rem;
  letter-spacing: -0.03rem;
}

.dynasystem .item.slide_nav .tit_wrap > *:first-child + p > * {
  color: #fff;
}

.dynasystem .item.slide_nav .tit_wrap > *:first-child + p > span {
  display: inline;
}

.dynasystem .item.slide_nav .paging_wrap {
  margin: 6rem auto 0;
  max-width: 40rem;
}

.dynasystem .item.slide_nav .paging_wrap .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  bottom: auto;
  width: auto;
  height: auto;
  gap: 1rem;
  position: relative;
}

.dynasystem .item.slide_nav .paging_wrap .inner button {
  display: table;
  margin: 1.3rem 0.6rem 0;
  padding: 0.75rem 3.5rem 0.95rem;
  width: auto;
  height: 5.6rem;
  background-color: #fff;
  border-radius: 5rem;
  color: #333;
  opacity: 1 !important;
}

.dynasystem .item.slide_nav .paging_wrap .inner button > span {
  display: table-cell;
  height: 100%;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  vertical-align: middle;
}

.dynasystem .item.slide_nav .paging_wrap .inner button.swiper-pagination-bullet-active {
  background-color: #27c9c9;
  color: #fff;
}

.dynasystem .item.slide_wrap {
  padding: 9rem 2rem 8rem;
  background-color: #f1f2f7;
}

.dynasystem .item.slide_wrap .slide {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-width: 46rem;
}

.dynasystem .item.slide_wrap .slide .swiper-wrapper {
}

.dynasystem .item.slide_wrap .slide .swiper-slide {
  padding: 0 3rem;
  text-align: center;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > strong {
  display: block;
  color: #082d8c;
  font-size: 2.125rem;
  font-weight: 800;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > p {
  margin-top: 2rem;
  font-size: 1.8rem;
  font-weight: #595959;
  line-height: 2.8rem;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img {
  margin-top: 2rem;
}

.dynasystem .item.slide_wrap .slide_control {
}

.dynasystem .item.slide_wrap .slide_control button {
  position: absolute;
  top: calc(54.25% - 2rem);
  z-index: 1;
  width: 30px;
  height: 52px;
  border: 0;
  background-repeat: no-repeat;
  padding: 0;
}

.dynasystem .item.slide_wrap .slide_control .slide_prev {
  background-image: url(../img/slide01_ar_l.svg);
  left: 0px;
  right: auto;
  background-size: auto;
}

.dynasystem .item.slide_wrap .slide_control .slide_next {
  left: auto;
  background-image: url(../img/slide01_ar_r.svg);
  right: 0;
  background-size: auto;
}

.dynasystem .item.slide_wrap .slide_control button i {
  display: block;
  color: #afb4be;
  font-size: 4rem;
  line-height: 1;
}

.dynasystem .item.slide_wrap .slide_control button:hover i,
.dynasystem .item.slide_wrap .slide_control button:focus i {
}

/* new */
.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img {
  position: relative;
  text-align: center;
  font-size: 0;
  line-height: 0;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a {
  display: inline-block;
  position: relative;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a > .inner {
  position: absolute;
  top: 4.175%;
  left: 3.35%;
  width: 93.3%;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a > .inner:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: 0.25s;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a > .inner:after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 6.6rem);
  left: calc(50% - 3.3rem);
  width: 6.6rem;
  height: 6.6rem;
  /* background: url(/_html/images/main/dynasystem_slide_ov_ico.svg) no-repeat 50% 50% / contain; */
  opacity: 0;
  transition: 0.25s;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a:hover > .inner:before,
.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a:focus > .inner:before {
  opacity: 0.2;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a:hover > .inner:after,
.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a:focus > .inner:after {
  top: calc(50% - 3.3rem);
  opacity: 1;
}

.dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img > a > .inner img {
}
.swiper-pagination-bullet {
  width: auto;
  height: auto;
  background-color: #fff;
  border-radius: 5rem;
  color: #333;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 400;
}
.swiper-pagination-bullet-active {
  background-color: rgb(33 139 234);
  color: #fff;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1320px) {
  .dynasystem .item.slide_nav .tit_wrap > *:first-child {
    font-size: 2rem;
  }
  .dynasystem .item.slide_nav .tit_wrap > * > span {
    font-size: 2.25rem;
  }
  .dynasystem .item.slide_nav .tit_wrap > *:first-child + p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1180px) {
  .dynasystem {
    flex-direction: column;
  }

  .dynasystem .item {
    width: 100%;
  }
  .dynasystem .item.slide_nav {
    padding: 3rem 3rem 3rem;
  }
  .dynasystem .item.slide_wrap {
    padding: 3rem 3rem 3rem;
  }
  .dynasystem .item.slide_nav .paging_wrap {
    margin: 2rem auto 0;
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .swiper-pagination-bullet {
    font-size: 0.925rem;
  }
  .dynasystem .item.slide_wrap .slide .swiper-slide > .thumb_img {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}

/* --------/////섹션03 다이나시스템 소개 끝-------- */

/* --------섹션04 고객사/협력사 -------- */
#sec04 {
  padding: 6.275rem 0 6.275rem;
}
#sec04 .more_btn {
  text-align: center;
}
#sec04 .more_btn a {
  display: inline-block;
  position: relative;
  padding: calc(0.75rem + 2px) calc(4rem + 2px) calc(0.75rem + 2px);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  border-radius: 4rem;
  transition: 0.4s;
}
#sec04 .more_btn a:hover {
  background-color: #365dcc;
  border: 1px solid #365dcc;
  color: #fff;
}
.mcustomer {
  margin: 2.725rem 0;
}
.mcustomer [class^="slide_"] {
  position: relative;
  padding: 1.525rem 0;
}
.mcustomer .slide_top {
}
.mcustomer .slide_bottom {
}
.mcustomer .swiper-slide {
  width: auto;
}
.slide_top .swiper-wrapper,
.slide_bottom .swiper-wrapper {
  transition-timing-function: linear !important;
  position: relative;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1320px) {
}

@media screen and (max-width: 1090px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .mcustomer [class^="slide_"] {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}

/* --------/////섹션04 고객사/협력사 끝-------- */

/***footer ****/
#foot {
  background: #0a1725;
  padding: 2.65rem 0;
}
.footwg {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.footwg:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #474960;
  transform: translatey(60px);
}

.footw01 {
  font-family: "play", "Pretendard";
}
.footw01 .logo {
  margin: 0 0 2.75rem;
  max-width: 220px;
}
.footw01 .copyright {
  color: #dadada;
  font-size: 1.125rem;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 1.45;
}
.footw01 .copyright span {
  color: #fff;
  font-weight: 400;
  padding: 0 4px 0 0;
}

.footw02 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footw02 .sns {
  margin: 0 0 2.75rem;
  width: 100%;
}
.footw02 .sns ul {
  display: flex;
  justify-content: space-around;
}
.footw02 .sns ul li {
  width: 35px;
}
.footw02 .btnw {
}
.footw02 .btnw .btn01 {
  margin: 0.425rem 0 0;
  width: 100%;
}
.footw02 .btnw .btn01 a {
  color: #fff;
  display: flex;
  background: #1a395b;
  padding: 0.625rem 1.75rem;
  border-radius: 10px;
  justify-content: flex-start;
  font-size: 1.125rem;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 300;
  align-items: center;
}
.footw02 .btnw.on .btn01 a {
  border-radius: 10px 10px 0px 0px;
}
.footw02 .btnw .btn01 a:hover {
  background: #2c5f97;
}
.footw02 .btnw .btn01 a img {
  margin: 0px 10px 0px 0px;
  height: 20px;
}
.footw02 .site_btn ul {
  display: none;
  background: #fff;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  width: 100%;
}
.footw02 .site_btn ul li {
}
.footw02 .site_btn ul li a {
  display: block;
  line-height: 2.2;
  transition: 0.3s;
}
.footw02 .site_btn ul li a:hover {
  background: #e8e8e8;
}
/* 반응형  css  base pc ---> mobile */
@media screen and (max-width: 1320px) {
}

@media screen and (max-width: 1090px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .footw01 .logo {
    margin: 0 0 2.5rem;
    width: 160px;
  }
  .footwg:before {
    display: none;
  }
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
  #foot {
    padding: 2.65em 0 8.65em;
  }
  .footwg {
    display: flex;
    flex-direction: column;
  }
  .footw01 {
    margin: 0 0 3rem;
  }

  .footw02 .sns ul {
    justify-content: center;
  }
  .footw02 .sns ul li {
    width: 2.25rem;
    margin: 0 12px;
  }

  .footw02 .sns {
    margin: 0 0 1rem;
  }
  .footw02 .btnw {
    width: 240px;
  }
}

@media screen and (max-width: 479px) {
  .footw01 {
  }
  .footw02 .btnw {
    display: flex;
    flex-direction: column;
  }
  .footw02 .btnw .btn01 {
    margin: 0 0rem;
  }
}

/***footer 끝 ****/

/***퀵메뉴 탑스크롤 버튼 ****/
.topBtn {
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 99999;
  display: none;
  width: 70px;
  height: 70px;
  border-radius: 70px;
  background: #fff url("../img/topBtn.png") center 20px no-repeat;
  border: 2px solid #333;
  color: #333;
  line-height: 1;
  font-family: "Oswald", sans-serif;
  font-size: 0.824rem;
  font-weight: 600;
  letter-spacing: 0.5pt;
  padding: 35px 0 0 2px;
  text-align: center; /*  box-shadow:10px 10px 20px rgba(0,0,0,0.15);  */
  transition: all 0.3s linear;
}
.topBtn:hover {
  background-image: url("../img/topBtn_up.png");
  border-color: #008cd6;
  background-color: #008cd6;
  color: #fff;
}

#quick {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  transition: 0.6s;
  z-index: 10000;
}
#quick.on {
  bottom: 90px;
}
#quick ul li {
  margin-bottom: 8px;
}
#quick ul li:last-child {
  margin-bottom: 0;
}
#quick ul li a {
  display: flex;
  width: 65px;
  height: 65px;
  line-height: 65px;
  transition: 0.3s;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 14%) 3px 6px 12px;
  align-items: center;
  justify-content: flex-start;
}

#quick ul li.onlineBtn {
  position: relative;
}
#quick ul li.onlineBtn a {
  position: relative;
  background: #264fc9;
  overflow: hidden;
}
#quick ul li.onlineBtn a:hover {
  width: 180px;
}
#quick ul li.onlineBtn a:hover span {
  opacity: 1;
  transition: 0.8s;
}
#quick ul li.onlineBtn span {
  margin-left: 14px;
  color: #fff;
  font-size: 1rem;
  letter-spacing: -0.5pt;
  font-weight: 500;
  opacity: 0;
}
#quick ul li.onlineBtn img {
  width: 36px;
  height: 36px;
  margin: 0px 0 0 13px;
}

#quick ul li.top_btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: none;
  z-index: 10000;
}
#quick ul li.top_btn > a {
  background: #fff url("../img/topBtn.png") center no-repeat;
  border: 1px solid #333;
}
#quick ul li.top_btn > a:hover {
  background-image: url("../img/topBtn_up.png");
  border-color: #6666ff;
  background-color: #6666ff;
}

/* 반응형  css  base pc ---> mobile */
@media screen and (max-width: 1610px) {
  #quick.on {
    bottom: 380px;
  }
  #quick ul li.top_btn {
    bottom: 310px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
  #quick {
    right: 5px;
  }
  #quick.on {
    bottom: 72px;
  }
  #quick ul li {
    margin-bottom: 0;
  }
  #quick ul li.top_btn {
    right: 5px;
    bottom: 20px;
  }
  #quick ul li.top_btn > a {
    background-size: 50%;
  }
  #quick ul li.onlineBtn img {
    width: 30px;
    height: 30px;
    margin: 0px 0 0 8px;
  }
  #quick ul li.onlineBtn a:hover {
    width: 130px;
    display: flex;
    justify-content: center;
  }
  #quick ul li.onlineBtn span {
    margin-left: 8px;
    width: 70px;
  }

  #quick ul li a {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 479px) {
}

/***퀵메뉴 탑스크롤 버튼 끝****/

/* 반응형  css  base pc ---> mobile */
@media all and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1320px) {
}

@media screen and (max-width: 1090px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 690px) {
}

@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}
