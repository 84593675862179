@charset "utf-8";

/* *******************
 * description : reset tag
 * date : 2023-02-23
 * 작성자  : 여준섭
******************* */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	box-sizing: border-box;
}

address, caption, cite, code,
dfn, em, var {
	font-style:normal;
	font-weight:normal;
}
label {
	cursor:pointer;
}
table{width:100%;border-collapse:collapse;border-spacing:0;}
/* Chrome Field Outline = None */
input:focus{outline:none;}
textarea:focus{outline:none;}
select:focus{outline:none;}
button:focus{outline:none;}

button{cursor: pointer;}

 html{
 
font-size:100%; /* 1rem = 16px */
 
-webkit-text-size-adjust:none;
 
-moz-text-size-adjust:none;
 
height: 100%;
}
 body{
	margin:0;
	padding:0;
	line-height: 1.6;
	letter-spacing:-.04125rem;
	 /* (((16px */
	/* word-wrap:break-word; ☆Not )) sub.css 본문 */
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
html, body {
   position: relative;
}

img{max-width:100%;vertical-align: bottom;height: auto;}

a{cursor:pointer;text-decoration:none;color:#444;}

ol,ul,li{list-style:none;}



/* float */

.clearfix {
  display: flow-root;
}

.clearboth {
    clear: both;
}

.clearfix:after {
    content: '';
    display: block;
    clear: both;
}

.center{text-align:center}



/* 반응형  css  base pc ---> mobile */

@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:768px){

}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}
 

