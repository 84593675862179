@charset "utf-8";


/***공통 레이아웃****/
#contents {position:relative;clear:both;opacity:1;top:0;z-index: 0;}
#contents_in{}
#contents_in.board{
    margin: 0px 0 9%;
}
.cont_line01{	
height: 0px;	
border-bottom: 1px dashed #ccc;	
margin: 4% 0;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
#contents .container{
	padding:0 calc(10px + 1%);
	}

}

/*******공통 레이아웃 끝*********/

/*///*****공통 배경컬러*******/
.bg_navi{
    background: #1f477c !important;
    color: #fff !important;
}
.bg_blue{
    background: #2a5fcc !important;
    color: #fff !important;
}
.bg_gray{
    background: #f5f8fc !important;
}
/*******공통 배경컬러 끝*******/


/********* table **********/
.table01{position: relative;}
.table01.col{border-top: 2px solid #000000;width: 100%;overflow: auto;}
.table01.col table thead th{font-size: 1.25rem;font-weight: 500;color: #111111;letter-spacing: -.3px;height: 64px;background: #fafafa;border-bottom: 1px solid #dfdede;padding: 0 15px;text-align: center;border-right: 1px solid #dfdede;}
.table01.col table tbody th,.table01.col table tbody td{font-size: 1rem;color: #444;letter-spacing: -.3px;height: 60px;border-bottom: 1px solid #dfdede;text-align: center;padding: 12px;box-sizing: border-box;border-right: 1px solid #dfdede;}
.table01.col table tbody th{border-right: 1px solid #e6e6e6;}
.table01.col table thead th:last-child, .table01.col table tbody td:last-child{
    border-right: none;
}
/********* table end**********/


/*//////////////////서브 비주얼 ///////////////*/
#sub_visual{position:relative;width:100%;text-align:center;height: 420px;background-color:#000;overflow:hidden;}
#sub_visual .subtop {width:100%;height:100%;animation: m_vsImg 2s ease-in-out;background-position:center;background-repeat:no-repeat;background-size:cover;}
#sub_visual .subtop_about {background-image: url('../img/sub/subvs_company.jpg');}
#sub_visual .subtop_business {background-image:url('../img/sub/subvs_business.jpg');}
#sub_visual .subtop_solution {background-image:url('../img/sub/subvs_solution.jpg');}
#sub_visual .subtop_recruit {background-image:url('../img/sub/subvs_recruit.jpg');}



#sub_visual .v_txt{position:absolute;left:50%;top:54%;transform:translate(-50%,-50%);width:100%;text-align:center;animation: blur_txt 1.2s;animation-fill-mode: b;}
#sub_visual .v_txt h2{font-size: 3.75rem;letter-spacing:0.6pt;font-weight:700;color:#fff;line-height:120%;font-family: "play";}
#sub_visual .v_txt .line{max-width: 2.5rem;height: 2px;background: #fff;margin: 1rem auto;}
#sub_visual .v_txt p{font-size: 1.425rem;letter-spacing:0.6pt;color:#fff;line-height:120%;font-weight: 200;animation: blur_txt 2.5s;animation-fill-mode: none;}

.splitting .char {
  animation: slide-in 1.5s cubic-bezier(.3, 0, .3, 1) both;
  animation-delay: calc(60ms * var(--char-index));
}

/*splitting 텍스트 애니메이션*/
@keyframes slide-in {
  from {
    transform: translateY(-1.125em)  scale(0.4);
    opacity: 0;
  }
}	

/*서브비주얼 애니메이션*/
@keyframes m_vsImg {
	0% { -webkit-transform:scale(1.1); -ms-transform:scale(1.1); transform:scale(1.1); }
	20% { -webkit-transform:scale(1.1); -ms-transform:scale(1.1); transform:scale(1.1); }
	100% { -webkit-transform:scale(1); -ms-transform:scale(1); transform:scale(1); }
}
/*서브타이틀 애니메이션*/
@keyframes blur_txt {
	0% { filter:blur(15px); opacity:0; }
	100% { filter:blur(0); opacity:1; }
}

/* 반응형  css  base pc ---> mobile */
 @media screen and (max-width:992px){
#sub_visual{
    height: 420px;
}
#sub_visual .v_txt h2{}
#sub_visual .v_txt p{}
}
@media screen and (max-width:768px){
#sub_visual{}
#sub_visual .v_txt h2{}
#sub_visual .v_txt p{}
}
@media screen and (max-width:599px){
#sub_visual{
    height: 230px;
}
#sub_visual .v_txt h2{
    font-size: 2.8rem;
}
#sub_visual .v_txt p{
    font-size: 1.1rem;
}
}

/*//////////////////서브 비주얼 끝///////////////*/


/*//////////////////서브 콘텐츠 배너 ///////////////*/
.bn_wg01.history{background: url(../img/company/history_bg01.jpg) center no-repeat;}
/* .bn_wg01.cert{background: url(../img/abilty/cert_bg01.png) center no-repeat;} */
/* .bn_wg01.aword{background: url(../img/abilty/aword_bg01.png) center no-repeat;} */

.bn_wg01{
    background-size: cover !important;
    padding: 5rem 0rem 5rem;
    text-align: center;
    font-family: 'Play';
    border-radius: 70px 0px 70px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}




.bn_wg01 .pic_w01{
 
max-width: 5rem;
}
.bn_wg01 .tit_w01{
    color: #ffffff;
    font-size: 2.25rem;
    font-weight: 800;
    margin: 8px 0;
}
.bn_wg01 .tit_w02{
    color: #fff;
    font-size: 1.5rem;
}


/* 반응형  css  base pc ---> mobile */

@media screen and (max-width:992px){
.bn_wg01{padding: 3rem 0rem 3rem;border-radius: 20px;}
}
 
@media screen and (max-width:690px) {
   .bn_wg01{padding: 1.5rem 0rem 1.5rem;border-radius: 20px;} 
 .bn_wg01 .pic_w01{
 max-width: 35px;
}

   .bn_wg01 .tit_w01{
    font-size: 1.6rem;
    margin: 2px 0;
}
 .bn_wg01 .tit_w02{
    font-size: 1rem;
}
    
}


/*//////////////////서브 콘텐츠 배너 끝///////////////*/


/*//////////////////서브 메뉴 시작///////////////*/
#lnb {position:absolute;top: -35px;width:100%;z-index:99;opacity:1;height: 70px;/* background:rgba(255,255,255,.1); -webkit-backdrop-filter: blur(10px);/* backdrop-filter: blur(10px); */animation: fadeIn 0.5s forwards ease-out;opacity:0;animation-delay: 0.4s;}
#lnb .lnb_w{
    max-width: 1500px;
    background: #fff;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0px 10px 22px rgb(0 0 0 / 8%);
    overflow: hidden;
}
#lnb ul{position:relative;text-align:center;max-width: 1000px;margin:0 auto;display:flex;/* border-left:1px solid rgba(255,255,255,0.2); */justify-content: center;}
#lnb ul.two li{width:100%}
#lnb li {position:relative;width:33.3%}
#lnb li a {position:relative;display:flex;justify-content:center;align-items:center;font-size: 1.125rem;color: #333;height: 70px;line-height: 70px;transition: all 0.3s cubic-bezier(0, 0, 0.29, 0.67);}
#lnb li a.on {font-weight:600;color: #fff;background: #3c59d6;}
#lnb li:hover a{background: #3b63e3;color:#fff;font-weight:700}
#lnb li:after{/* width:1px; */height:100%;background:rgba(255,255,255,0.2);content:'';display:block;display: block;position: absolute;right: 0;top: 50%;transform: translateY(-50%);}

/*서브메뉴 애니메이션*/
@keyframes fadeIn {
	0% {opacity:0;}
	100%  {opacity:1;}
}

/* 반응형  css  base pc ---> mobile */


@media screen and (max-width:599px){
#lnb{
	display: none;
}
}



/*//////////////////서브 메뉴 끝///////////////*/


/*/////////////////////////////공용 타이틀 시작////////////////////////////*/
/*//////////////////서브 콘텐츠 타이틀 시작///////////////*/
.tit_area{position:relative;text-align:center;padding: 120px 0 60px 0;z-index: 1;}
.tit_area h3{position:relative;font-size: 3.125rem;color:#222;line-height:1;max-width: fit-content;margin: 0 auto;max-height: fit-content;}
.tit_area h3:before{width: 1.75rem;height: 1.75rem;background: #3b63e3;content:'';display: block;position: absolute;right: 0;top: -17px;left: -27px;border-radius: 4px;z-index: -1;}
.tit_area h3:after{width: 1.9rem;height: 1.9rem;background: #789eff;content:'';display: block;position: absolute;right: 0;top: -8px;left: -18px;border-radius: 4px;z-index: -2;}
/*//////////////////서브 콘텐츠 타이틀 끝///////////////*/

/*////////소단원 타이틀/////////*/
.tit_area01{position:relative;text-align:center;padding: 0px 0 3.6rem 0;}
.tit_area01 h3{position:relative;font-size: 3rem;color:#222;line-height:1;max-width: fit-content;margin: 0 auto;max-height: fit-content;font-family: 'poppins',"Pretendard";}
.tit_area01 h3 span{
    color: #365dcc;
}
.tit_area01 p {
    margin: 1.263rem auto 0;
    font-size: 1.25rem;
    color: #555;
 }
.tit_area01 .line{
    width: 2.5rem;
    height: 2px;
    background: #333;
    margin: 1.563em auto 0;
}

.tit_area02{position:relative;padding: 0px 0 50px 0;}
.tit_area02 h3{position:relative;font-size: 3.125rem;color:#222;line-height:1;max-width: fit-content;max-height: fit-content;font-family: 'poppins',"Pretendard";}
.tit_area02 h3 span{
    color: #fc7e2f;
}
.tit_area02 .line{
    width: 2.5rem;
    height: 2px;
    background: #333;
    position: absolute;
    bottom: 20px;
    left: 3px;
}
/*////소단원 타이틀 끝////*/

/* 반응형  css  base pc ---> mobile */
@media screen and (max-width:690px) {
.tit_area h3{
    font-size: 2rem;
}
.tit_area01{
    padding: 0px 0 20px 0;
}
.tit_area01 h3{
    font-size: 2.2rem;
}
    .tit_area02{
    padding: 0px 0 40px 0;
}
.tit_area02 h3{
    font-size: 2.4rem;
}
}


@media screen and (max-width:599px){
.tit_area{
    padding: 10% 0 6% 0;
}
}

@media screen and (max-width:479px){

}

/*/////////////////////////////공용 타이틀  끝////////////////////////////*/


/*************회사개요 시작***************/

/*비전*/
.about .content01{
    background: url(../img/company/about_bg01.jpg) center no-repeat fixed;
    background-size: cover;
    padding: 5rem 0rem 5rem;
}
.about .content01 .title{
    color: #fff;
    font-size: 2.725rem;
    text-align: center;
    margin: 0 0 4rem;
}
.about .content01 .cbox_w{}
.about .content01 .cbox_w ul{
    display: flex;
    justify-content: center;
    gap: 3%;
    flex-wrap: wrap;
}
.about .content01 .cbox_w ul li{
    width: 30%;
}
.about .content01 .cbox_w ul li .cb01{
    padding: 2rem 2rem;
    position: relative;
    background: rgb(13 17 39 / 40%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 1rem;
    min-height: 320px;
}
.about .content01 .cbox_w ul li .cb01 .tg01{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.85rem;
    color: #fff;
}
.about .content01 .cbox_w ul li .cb01 .tg01 figure{
    width: 60px;
    margin: 0 0 0.75rem;
}
.about .content01 .cbox_w ul li .cb01 .tg01 figure img{}
.about .content01 .cbox_w ul li .cb01 .tg01 .t01{
    text-align: center;
    font-size: 1.275rem;
}
.about .content01 .cbox_w ul li .cb01 .tg01 .t02{
    text-align: center;
    font-size: 1rem;
    line-height: 1.45;
    color: #d4dde8;
}
/*//////비전 끝//////*/


/*회사소개*/
.about .content02{
    margin: 4rem 0 2rem;
}
.intro_w01{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.35rem;
}
.intro_w01 figure{}
.intro_w01 figure img{}
.intro_w01 .title{
    font-size: 3.125rem;
    text-align: center;
}
.intro_w01 .title span{
    color: #fc7e2f;
    font-weight: 600;
}
.intro_w01 .line{
    height: 40px;
    width: 1px;
    background: #888;
}
.intro_w01 .t01{
    font-size: 1.3rem;
    text-align: center;
    color: #555;
    line-height: 1.75;
    position: relative;
    max-width: 1100px;
}
.intro_w01 .t01:before{ /* content: url("../img/company/about_textb01.png"); */ display: block;position: absolute;top: -12px;left: -86px;border-radius: 99px;z-index: -1;}
.intro_w01 .t01:after{ /* content:url("../img/company/about_textb02.png");*/ display: block;position: absolute;right: -80px;top: -12px;border-radius: 99px;z-index: -1;}


/*/////회사소개 끝*/

/*회사정보*/
.about .content03{
   
padding: 0 0 6em;
}
/* info-area */
 .info-box-wrap {border-right: 1px solid #ddd;border-top: 3px solid #365dcc;margin: 0 0 3em;border-radius: 1rem;overflow: hidden;}
  .info-box-wrap .info-box {display: flex;justify-content: space-between;}
  .info-box-wrap .info-box .desc,.info-box-wrap .info-box .title {padding: 16px 30px;}
 .info-box-wrap .info-box .title {display: block;width: 20%;min-width: 120px;font-size: 18px;font-weight: 500;color: #fff;border-bottom: 1px solid rgba(255, 255, 255, .21);background: #0e1f42;text-align: center;display: flex;justify-content: center;align-items: center;}
  .info-box-wrap .info-box .desc {width: 80%;font-size: 17px;color: #555;border-bottom: 1px solid #ddd;background: #fff;word-break: keep-all;}
/****** info-area 끝*/

/* 회사소개서 다운로드 버튼 wrap */
.info_btn_wrap{}
/* 회사소개서 다운로드 버튼 wrap 끝 */

/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	
.about .content01 .cbox_w ul{
    row-gap: 1rem;
    flex-direction: column;
}
	.about .content01 .cbox_w ul li{
    width: 100%;
}
	.about .content01 .cbox_w ul li .cb01{
    min-height: auto;
}
}

@media screen and (max-width:1090px){
/*회사소개*/    
.intro_w01 figure{
    width: 15vw;
}
    .intro_w01 .t01:before{ /* content:url("../img/company/about_textb01.png"); */ display: block;top: -48px;left: 1%;border-radius: 99px;z-index: -1;transform: scale(0.6);}
.intro_w01 .t01:after{ /* content:url("../img/company/about_textb02.png"); */ right: 1%;bottom: -402%;z-index: -1;transform: scale(0.6);}
}

@media screen and (max-width:992px){
/*비전*/    
.about .content01 .title{
    font-size: 1.8rem;
}
    .about .content01 .cbox_w ul li .cb01 .tg01 figure{
}
.about .content01 .cbox_w ul li .cb01 .tg01 .t01{
    font-size: 1rem;
}
}
 
@media screen and (max-width:768px){
/*비전*/
    .about .content01{
    padding: 7% 0rem 8%;
}
.about .content01 .cbox_w ul{
}
.about .content01 .cbox_w ul li{
}
     .about .content01 .cbox_w ul li .cb01 .tg01 .t01{
    font-size: 3.4vw;
    }
/*회사소개*/
.intro_w01 .title{
    font-size: 2.4rem;
}
    .intro_w01 .t01{
    font-size: 1.1rem;
}
         
/*회사정보*/
.info-box-wrap .info-box .desc,.info-box-wrap .info-box .title {padding: 16px;}
 .info-box-wrap .info-box .title {font-size: 14px;}
  .info-box-wrap .info-box .desc {font-size: 14px;}

}

@media screen and (max-width:690px) {
    /*비전*/

    /*회사소개*/
   .info-box-wrap .info-box .desc, .info-box-wrap .info-box .title{
    padding: 10px;
}
}




@media screen and (max-width:599px){
    /*회사정보*/
.info-box-wrap .info-box .desc, .info-box-wrap .info-box .title{}
}

@media screen and (max-width:479px){

}

/*/////회사정보 끝*/

/*************회사개요 끝***************/


/*************ceo인사말 시작***************/
.ceo .content01{
    padding: 0 0 5vw;
}
.ceo .content01 .wg01{
    display: flex;
    gap: 3%;
}
.ceo .content01 .wg01 .w01{
    width: 35%;
}
.ceo .content01 .wg01 .w01 img{
    border-radius: 1.35vw;
}
.ceo .content01 .wg01 .w02{
    width: 65%;
    background: #f9f9f9;
    padding: 2.2rem 2.4rem 2.8rem;
    border-radius: 1.35vw;
}
.ceo .content01 .wg01 .w02 .tg01{}
.ceo .content01 .wg01 .w02 .tg01 .t01{
    font-size: 2.25rem;
    font-weight: 400;
    margin: 0 0 1.25rem;
    color: #444;
}
.ceo .content01 .wg01 .w02 .tg01 .t02{
    font-size: 1.65rem;
    margin: 0 0 1.75rem;
    color: #555;
}
.ceo .content01 .wg01 .w02 .tg01 .t03{
    font-size: 1.25rem;
    margin: 0 0 2rem;
    color: #666;
    line-height: 1.5;
}
.ceo .content01 .wg01 .w02 .tg01 .sign_ceo{
    text-align: right;
    font-size: 1.75rem;
    margin: 3rem 0 0;
}
.ceo .content01 .wg01 .w02 .tg01 .sign_ceo span{
    font-weight: 500;
    margin-right: 0.75vw;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:920px){
.ceo .content01 .wg01{
    flex-direction: column;
    row-gap: 1.5rem;
}
	.ceo .content01 .wg01 .w01{
    width: 100%;
}
.ceo .content01 .wg01 .w02{
    width: 100%;
}
} 

 
@media screen and (max-width:768px){

}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}

/*************ceo인사말 끝***************/


/*************기업이념 시작***************/
.philosophy .content01{
    margin: 0 0 5%;
}
.philosophy .content01 .wg01{}
.philosophy .content01 .wg01 .t01{
    font-size: 2.125rem;
    color: #333;
    padding-top: 10px;
    margin: 0 0 1rem;
    position: relative;
}
.philosophy .content01 .wg01 .t01:before{position:absolute;display:inline-block;content:"";width: 0.625rem;height: 0.625rem;background: #6180ff;border-radius:50%;top:0;}
.philosophy .content01 .wg01 .t01 span{
    color: #959595;
    margin: 0px 0 0 1.475rem;
    font-weight: 400;
    font-style: italic;
    font-size: 1.75rem;
}
.philosophy .content01 .wg01 .box-slogan{
    background: #f6f6f6;
    text-align: center;
    padding: 2.75rem 1.25rem;
    margin-bottom: 40px;
    border-radius: 0.65rem;
}
.philosophy .content01 .wg01 .box-slogan span{
    position: relative;
    display: inline-block;
    font-weight: 300;
    font-size: 1.475rem;
    letter-spacing: -0.5px;
    line-height: 1.81;
    padding: 0 4%;
    word-break: keep-all;
}
.philosophy .box-slogan span:before,.philosophy .box-slogan span:after{position:absolute;display:inline-block;top:-25px;font-weight:900;font-size:72px;color: #d7d7d7;}
.philosophy .box-slogan span:before{content:"“";left: -10px;}
.philosophy .box-slogan span:after{content:"”";right: -10px;}
.philosophy .content02 .wg01{
}

.philosophy .content02 .titg01{
	 margin: 0px 0 2.5rem;
}


.philosophy .content02 .titg01 h3.t01{
    text-align: center;
    font-size: 2rem;
    color: #44599c;   
}
.philosophy .content02 .titg01 h4.t02{
    text-align: center;
    font-size: 1.65rem;
    color: #8b8b8b;
    font-weight: 300;
}

.philosophy .content02{}

.philosophy .content02 ul.circle_wg{width: 75%;display: flex;justify-content: center;margin: 0px auto 3%;}
ul.circle_wg li{
    width: 27%;
    margin: 0px -1.5%;
}
.philosophy .content02 ul.circle_wg li .cbw{
	padding: 0 0 100%;
    position: relative;
    text-align: center;
}
.philosophy .content02 ul.circle_wg li .cbw .cbox{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 100%;
	flex-direction: column;
	row-gap: 8%;
	transition: 0.3s;
	background: rgb(7 64 156 / 75%);
}
.philosophy .content02 ul.circle_wg li .cbw .cbox:hover{
    background: rgb(48 114 253 / 80%);
}
.philosophy .content02 ul.circle_wg li .cbw .cbox .ig01{
    width: 100%;
    max-width: 70px;
}
.philosophy .content02 ul.circle_wg li .cbw .cbox .ig01 img{}
.philosophy .content02 ul.circle_wg li .cbw .cbox .tg01{
    color: #fff;
}
.philosophy .content02 ul.circle_wg li .cbw .cbox .tg01 .t01{
    font-size: 1.55rem;
    margin: 0 0 0.6rem;
}
.philosophy .content02 ul.circle_wg li .cbw .cbox .tg01 .t02{
    font-size: 1.125rem;
}

.philosophy .content02 .wg01 .txt{width: 75%;margin: 0 auto;font-size: 1.25rem;line-height: 1.45;}
.philosophy .content02 .wg01 .txt b{
    font-size: 1.65rem;
    color: #193680;
}



.philosophy .content03{}
.philosophy .content03 .wg01{
    display: flex;
    gap: 2vw;
    margin: 0 0 2rem;
}
.philosophy .content03 .wg01.row02{
}
.philosophy .content03 .wg01 .w01{
    width: 30%;
    min-width: 320px;
}
.philosophy .content03 .wg01 .w01 img{
 border-radius: 0.5rem;
}

.philosophy .content03 .wg01 .w02 {
    width: calc(100% - 320px);
    background: #f7f7f7;
    padding: 2% 2.5% calc(2.5% + 5px);
    border-radius: 0.5rem;
 }

.philosophy .content03 .wg01 .w02 .tg01{} 
.philosophy .content03 .wg01 .w02 .tg01 .t01{
    font-size: 2.25rem;
    font-weight: 500;
    color: #393d47;
    margin: 0 0 0.25rem;
}
.philosophy .content03 .wg01 .w02 .tg01 .t02{
    font-size: 2rem;
    font-weight: 400;
    color: #444;
    padding: 0 0 1rem;
    border-bottom: 1px solid #dadada;
    margin: 0 0 1.65rem;
}
.philosophy .content03 .wg01 .w02 .tg01 .t03{
    font-size: 1.175rem;
    line-height: 1.5;
    color: #666;
}


.philosophy .content04{}
.philosophy .content04 ul.list_wg{
    display: flex;
    gap: 1vw;
}
.philosophy .content04 ul.list_wg li{
    width: 30%;
}

.philosophy .content04 .titg01{
	 margin: 0px 0 2.5rem;
}

.philosophy .content04 .titg01 h3.t01{
    text-align: center;
    font-size: 2rem;
    color: #44599c;   
}

.philosophy .content04 .wg01{
    display: flex;
    margin: 0 0 2rem;
    flex-direction: column;
    border-radius: 0.65vw;
    overflow: hidden;
}
.philosophy .content04 .wg01.row02{
}
.philosophy .content04 .wg01 .w01{
}
.philosophy .content04 .wg01 .w01 img{
 border-radius: 0;
}

.philosophy .content04 .wg01 .w02{
    background: #f7f7f7;
    padding: 5% 6%;
    border-radius: 0.5rem;
    min-height: 145px;
}
.philosophy .content04 .wg01 .w02 .tg01{} 
.philosophy .content04 .wg01 .w02 .tg01 .t01{
    font-size: 1.25rem;
    font-weight: 500;
    color: #393d47;
    margin: 0 0 0.5rem;
}
.philosophy .content04 .wg01 .w02 .tg01 .t02{
   font-size: 1rem;
   line-height: 1.5;
   color: #666;
}


/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	
.philosophy .content04 ul.list_wg{
    flex-wrap: wrap;
}
	.philosophy .content04 ul.list_wg li{
    width: 49.4%;
}
}

@media screen and (max-width:1190px){	
.philosophy .content02 ul.circle_wg{
    width: 100%;
}

	.philosophy .content02 .wg01 .txt{
    width: 100%;
}
}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:920px){
	
.philosophy .content02 ul.circle_wg li .cbw .cbox .ig01{max-width: 6vw;}
	ul.circle_wg li{
    width: 35%;
}
	.philosophy .content04 .wg01 .w02{
    min-height: 120px;
}
		.philosophy .content03 .wg01 .w02 .tg01 .t02{
    font-size: 1.65rem;
}
} 

 
@media screen and (max-width:768px){
.philosophy .content03 .wg01{
    flex-direction: column;
    align-items: center;
}
	.philosophy .content03 .wg01 .w01{
    width: 50%;
}
	.philosophy .content03 .wg01 .w02{
    width: 100%;
    padding: 3% 3.5%;
}
}

@media screen and (max-width:690px) {

}


@media screen and (max-width: 599px){
	.philosophy .content02 ul.circle_wg li .cbw .cbox{
    row-gap: 4%;
}
	.philosophy .content02 ul.circle_wg li .cbw .cbox .tg01 .t01{
    font-size: 1.35rem;
}
.philosophy .content02 ul.circle_wg li .cbw .cbox .tg01 .t02{
    font-size: 1rem;
}
	.philosophy .content02 .wg01 .txt{
    font-size: 1.1rem;
}
	.philosophy .content02 .wg01 .txt b{
    font-size: 1.35rem;
}

}

@media screen and (max-width:479px){

}


/*************기업이념 끝***************/



/*************회사연혁 시작***************/
.history .content01{
    position: relative;
    z-index: 1;
}


.history .content02{
    position: relative;
    z-index: 2;
    background: url(../img/company/history_bg02.gif)repeat top;
}
/*연혁 네비게이션*/
.his_tap{
    position: fixed;
    bottom: 50%;
    transform: translate(0px, 100px);
    display: none;
    z-index: 10;
}
.his_tap ul{}
.his_tap ul li{padding: 4px 0;}
.his_tap ul li a{
    font-family: 'Play','Pretendard';
    display: block;
    padding: 0 0 0 24px;
    color: #666;
    font-weight: 300;
    position: relative;
}
.his_tap ul li a.on{
    color: #1a67d7;
    font-weight: 600;
}
.his_tap ul li a:before{
    content:''; width:0; height:2px; background:#222; position:absolute; left:0; top:10px; transition:all .3s linear;
   
}
.his_tap ul li a.on:before{
   width: 12px;
}
.his_navi{
}

/*연혁 리스트*/
.tableWrap {padding: 60px 0 0; position:relative; width:100%; margin:0 auto; overflow-x:hidden;}
.tableWrap::before {content:""; display:block; width:1px; height:100%; position:absolute; top:0; left:50%; background:#ddd; z-index:-1;}
.his_in:first-child{
}
.his_in{display:flex;width: 100%;align-items:start;justify-content: space-between;flex-direction: row-reverse;padding: 80px 0 50px;box-sizing:border-box;margin:0 auto;}
.his_in .his_box{width:calc(50% - 60px);}
.his_in .his_txt{}
.his_in .his_txt h6{position:relative;font-size:45px;padding:0 0 14px;color:#333;font-weight:700;line-height:130%;}
.his_in .his_txt h6:after{content:''; display:block; width:8px; height:8px; background: #0183c1; border-radius: 50%; position:absolute; left: -64px; top:25px;
overflow: hidden; animation: dot linear 1.5s infinite;}
.his_in .his_txt h6:before{width:8px;height:8px;content: '';display: block;background-color: #0183c1;opacity:0.2;border-radius: 50%;position: absolute;left: -64px;top: 25px;z-index: -1;animation: dots 1.5s ease-in-out infinite;}
.his_in .his_txt .his_list{word-wrap: break-word;color: #444;font-size: 1.125rem;word-break:keep-all;box-sizing:border-box;padding: 0 0 6px 13px;position:relative;text-align:left;}
.his_in .his_txt .his_list::after{content:""; width: 5px; height: 5px; background: #0183c1; border-radius: 50%; position: absolute; top: 12px; left: -2px;}
/* .his_in .his_txt .his_list span{display:inline-block; font-size:0.941rem; font-weight:700; color:#222; position:absolute; left:0; top:0;}  */


@keyframes dots {
    0%{
      opacity: 0.5;
      transform: scale(1);
    }
    100%{
      opacity: 0;
      transform: scale(4);
    }
}



.his_in:nth-child(even){flex-direction: row;}
.his_in:nth-child(even) .his_txt h6{text-align:right;}
/* .his_in:nth-child(even) .his_txt h6:before{left:auto; right:-87px;} */
.his_in:nth-child(even) .his_txt h6:after{left:auto;right: -64px;}
.his_in:nth-child(even) .his_txt h6:before{left:auto;right: -64px;}
.his_in:nth-child(even) .his_txt .his_list{text-align:right; padding:0 13px 8px 0;}
/* .his_in:nth-child(even) .his_txt .his_list span{left:auto;} */
.his_in:nth-child(even) .his_txt .his_list::after{left:auto; right: -2px;}


.tableWrap .his_in:last-child {padding-bottom: 100px;}
/*//연혁 리스트 끝*/


 /* 반응형  css  base pc ---> mobile */
 @media screen and (max-width:992px){
   
}
     
/*연혁 리스트*/
.tableWrap {padding:50px 0 0;}
.his_in{padding: 70px 0 40px;}
.his_in .his_box{width:calc(50% - 40px);}
.his_in .his_txt h6{font-size:34px;padding:0 0 12px;}
 .his_in .his_txt h6:before{left: -44px;top: 17px;}
.his_in .his_txt h6:after{left: -44px; top:17px;}
.his_in:nth-child(even) .his_txt h6:before{left: auto;right: -44px;}
.his_in:nth-child(even) .his_txt h6:after{left:auto;right:-44px;}
.his_in .his_txt .his_list{padding:0 0 8px 10px;}
.his_in .his_txt .his_list::after{top: 10px;}
.his_in .his_img .imgBox{text-align:right;}
.tableWrap .his_in:last-child {padding-bottom: 60px;}


 
@media screen and (max-width:768px){

}

@media screen and (max-width:690px) {
 
    
    .his_tap{display: none !important;}
    
	/*연혁 리스트*/
.tableWrap {padding:40px 0 0;  margin:0 auto;}
.tableWrap::before {left:10px;}
.his_in{padding: 20px 0 40px 30px;flex-wrap: wrap;}
.his_in .his_box{width:100%;}
.his_in .his_txt h6{font-size:22px; padding:0 0 10px; text-align:left !important;}
     .his_in .his_txt h6:before{left: -24px;top: 11px;}
.his_in .his_txt h6:after{width:6px; height:6px; left:-23px !important; right:auto !important; top:12px;}
.his_in .his_txt .his_list{padding:0 0 6px 10px !important; text-align:left !important;}
.his_in .his_txt .his_list::after{top: 7.6px;}
.his_in:nth-child(even) .his_txt .his_list::after{left: -2px; right: auto;}
    .his_in:nth-child(even) .his_txt h6:before{left: -24px;right: auto;}
.his_in .his_txt .his_list span{right:auto; left:0 !important;}
.tableWrap .his_in:last-child {padding-bottom: 40px;}
}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}


/*////************회사연혁 끝***************/


/*************조직도 시작***************/

.organization .content01{
    padding: 4rem 0 6em;
    background: #eef4f7;
    position: relative;
    text-align: center;
}


/*************조직도 끝***************/



/*************오시는길 시작***************/
.location .content01{
    margin: 0 0 60px;
}
.location .content01 .wg01{
    display: flex;
    justify-content: space-between;
    gap: 2%;
}
.location .content01 .wg01 .w01{
}
.location .content01 .wg01 .w01 .picw01{
    position: relative;
}
.location .content01 .wg01 .w01 .picw01 .pic01{
}
.location .content01 .wg01 .w01 .picw01 .pic01 img{
    border-radius: 40px;
}
.location .content01 .wg01 .w01 .picw01 .tit_wg01{
    position: absolute;
    top: 0;
    bottom: 10%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    font-family: 'play',"Pretendard";
}
.location .content01 .wg01 .w01 .picw01 .tit_wg01 .tit_w01{
    font-size: 2.25rem;
    font-weight: 600;
}
.location .content01 .wg01 .w01 .picw01 .tit_wg01 .tit_w02{
    font-size: 1.5rem;
}

.location .content01 .wg01 .w02{
    width: 41%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.location .content01 .wg01 .w02 .txt-box{
}
.location .content01 .wg01 .w02 .txt-box .tit{
    font-size: 2.5rem;
    margin: 0 0 25px 0;
}

.location-table{width:100%;border-top:1px solid rgba(0,0,0,0.1);margin: 0 0 50px 0;}
.location-table tbody tr{}
.location-table tbody tr th, .location-table tbody tr td{font-size: 1.125rem;line-height:1.75em;letter-spacing:-0.65px;border-bottom:1px solid rgba(0,0,0,0.1);padding:10px 0;}
.location-table tbody tr th{font-weight: 500;color:#000;text-align:left;}
.location-table tbody tr td{color: #555;word-break:keep-all;}
.location-table tbody tr td .txt span{display: inline-block; margin-right:25px;}
.location-table tbody tr td .txt span:last-child{margin-right:0px;}

.loca_btn {position: relative;top: 0;right:0;border: 1px solid #ddd;box-sizing:border-box;font-size:0;display:inline-block;background: #f9fbfc;border-radius: 10px;overflow: hidden;display: flex;width: fit-content;margin: 0 auto;}
/* .loca_btn a.naver {background-image:url('../img/contact/ico_naver.png');} */
/* .loca_btn a.kakao {background-image:url('../img/contact/ico_kakao.png');} */
/* .loca_btn a.google {background-image: url('../img/contact/ico_google.png');border-right: 0px;} */
.loca_btn a {display: block;width:170px;height:65px;font-size: 1.063rem;border-right:1px solid #ddd;color:#222;letter-spacing:-0.7pt;line-height:65px;font-weight:400;padding-left:75px;box-sizing:border-box;transition:all 0.3s;background: no-repeat center left 20px;}


.loca_btn a:hover {color: #ffffff;transition:all 0.3s;background-color: #5165a0;}


.location .content02{
    margin: 0px 0 80px;
}
.location .content02 .w01{}
.location .content02 .w01 .map_warp{
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    background: #fff;
    box-shadow: 0 8px 15px rgb(0 0 0 / 8%);
    padding: 35px 35px;
    border-radius: 40px;
}
.location .content02 .w01 .map_warp .root_daum_roughmap{
    width: 100%;
    padding-bottom: 45.25%;
    border-radius: 30px;
}
.location .content02 .w01 .map_warp .root_daum_roughmap .wrap_map{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.location .content02 .w01 .map_warp .root_daum_roughmap .hide{
    display: none;
}


.location .content03{
    margin: 0 0 100px;
}
.location .content03 .tra_box{
    box-shadow: 0 8px 15px rgb(0 0 0 / 8%);
    padding: 55px 30px;
    border-radius: 40px;
    display: flex;
    justify-content: space-around;
}
.location .content03 .tra_box .w01{
    display: flex;
    gap: 10%;
    width: 45%;
}
.location .content03 .tra_box .w01  .picw01{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.location .content03 .tra_box .w01  .picw01 div{
    margin: 0 0 30px;
}
.location .content03 .tra_box .w01  .picw01 img{
    height: 120px;
}
.location .content03 .tra_box .w01  .picw01 .t01{
    margin: 0 auto;
    font-size: 1.25rem;
    background: #0d2a4e;
    padding: 4px 24px;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    width: fit-content;
}
.location .content03 .tra_box .w01 .ctw01{
    display: flex;
    flex-direction: column;
}
.location .content03 .tra_box .w01 .ctw01 .txtwg01{
    margin: 0px 0 40px;
}
.location .content03 .tra_box .w01 .ctw01 .txtwg01:last-child{margin:0;}
.location .content03 .tra_box .w01 .ctw01 .txtwg01 .t01{
    font-size: 1.25rem;
    margin: 0 0 5px;
    font-weight: 600;
}
.location .content03 .tra_box .w01  .ctw01 .txtwg01 .t02{
    font-size: 1.125rem;
}

/* 반응형  css  base pc ---> mobile */

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1238px){	

    .location .content01 .wg01 .w01{
    width: 50%;
}
    .location .content01 .wg01 .w02{
    width: 50%;
}
}

@media screen and (max-width:1090px){
.location .content01 .wg01{
    flex-direction: column;
    gap: 20px;
}
    .location .content01 .wg01 .w01{
    width: 75%;
    margin: 0 auto;
}
    .location .content01 .wg01 .w02{
    width: 100%;
}
}

@media screen and (max-width:992px){
.location .content03 .tra_box .w01{
    gap: 25px;
    flex-direction: column;
}
}
 
@media screen and (max-width:768px){
.location .content01 .wg01 .w01{
    width: 90%;
}
    .location .content01 .wg01 .w01 .picw01 .pic01 img{
    border-radius: 20px;
}
    .location .content01 .wg01 .w01 .picw01 .tit_wg01 .tit_w01{
    font-size: 1.65rem;
}
.location .content01 .wg01 .w01 .picw01 .tit_wg01 .tit_w02{
    font-size: 1.1rem;
}
    .location .content03 .tra_box{
    padding: 30px 25px;
    border-radius: 20px;
}
}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){
    .location .content01{
    margin: 0 0 40px;
}
    .location .content02{
    margin: 0px 0 60px;
}
    .location .content03{
    margin: 0 0 60px;
}
    .location-table{
    margin: 0 0 30px 0;
}
.location-table tbody tr th, .location-table tbody tr td{
    padding: 8px 0;
    line-height: 1.75em;
}
    .loca_btn{
    width: 100%;
}
    .loca_btn a{
    width: 33%;
    background: no-repeat center left 15px;
    padding-left: 60px;
}
    .location .content02 .w01 .map_warp{
    border-radius: 20px;
    padding: 3% 3%;
}
    .location .content02 .w01 .map_warp .root_daum_roughmap{
    border-radius: 15px;
}
    .location .content03 .tra_box{
    flex-direction: column;
    align-items: center;
    gap: 35px;
}
    .location .content03 .tra_box .w01{
    width: 100%;
}
    .location .content03 .tra_box .w01 .picw01 div{
    margin: 0px 0 15px;
}
    .location .content03 .tra_box .w01 .picw01 img{
    height: 80px;
}
}

@media screen and (max-width:479px){
.location .content03 .tra_box .w01 .picw01 .t01{
    font-size: 1rem;
}
}

/*////************오시는길  끝***************/


/*************사업분야 시작***************/

.bs_field .content01{
    margin: 0 0 60px;
}

.bs_field .titg01{
    margin: 0 0 4%;
}
.bs_field .titg01 h2{
    font-size: 2.75rem;
    text-align: center;
    margin: 0 0 1%;
    color: #2c52bb;
}
.bs_field .titg01 p.t01{
    font-size: 1.25rem;
    background: #f9f9f9;
    padding: calc(2% + 6px) calc(2% + 6px);
    border: 1px solid #e7e7e7;
    border-radius: 0.75rem;
    color: #555;
}
.co_tit{
    font-size: 2.25rem;
    text-align: center;
    margin: 0 0 2.5rem;
}
.co_tit::after{content: '';display: block;border-top: 8px solid #5f83df;border-radius: 20px;height: 2rem;transform: translate(0px, 1rem);}
.co_tit b{
 }

.in_cont{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
.in_cont.col2 {
    flex-direction: row;
    max-width: 1500px;
    flex-wrap: wrap;
    gap: 2%;
}
.in_cont.col2 .co_box{
    width: 49%;
}

.in_cont .co_box{
    display: flex;
    gap: 4%;
    width: 100%;
    border-bottom: 1px dashed #ccc;
    padding: 0 0 2.5%;
    margin: 0 0 2.5%;
    align-items: center;
}
.in_cont .co_box .w01{}

.circle{
	width: 200px;
	height: 200px;
	border-radius: 50%;
	position: relative;
	text-align: center;
	margin: 0 auto;
}
.circle::before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/business/icon_dot.png) no-repeat center / 100% auto;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
    animation: dot 8s linear infinite;
}
.circle img{
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background: #fff;
	margin: 25px auto 0;
	vertical-align: middle;
	box-shadow: 0 30px 40px rgba(0, 0, 0, .07);
}

@keyframes dot {
	0% { transform:rotate(0); }
	100% { transform:rotate(360deg); }
}

.in_cont .co_box .w02{
    width: calc(100% - 170px);
}
.in_cont .co_box .w02 .tg01{
}
 .in_cont .co_box .w02 .tg01 .t01{
    font-size: 1.85rem;
    font-weight: 500;
    margin: 0 0 2rem;
    color: #222;
}
.in_cont .co_box .w02 .tg01 .t01 p{
    font-size: 1.25rem;
    font-weight: 300;
    color: #666;
}
.in_cont .co_box .w02 .tg01 .t01::after{
	content: '';
	display: block;
	width: 2.5rem;
	height: 0;
	border-bottom: 2px solid #336ce3;
	transform: translate(0px, 0.6rem);
}
 .in_cont .co_box .w02 .tg01 .t02{
    font-size: 1.15rem;
    color: #555;
}



/***탭 콘텐츠*****/
.tabList  {position: relative;}
.bs_field  .tabList > nav {
    max-width: 1100px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto 4%;
}
.bs_field  .tabList > nav.fixed{
    position: fixed;
    top: 7.5%;
    z-index: 999;
    box-shadow: 0 15px 20px rgb(0 0 0 / 10%);
    left: 50%;
    max-width: 100%;
}

.bs_field  .tabList .tabMenu {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0;
    display: flex;
}
.bs_field  .tabList .tabMenu li {
    width: 50%;
    height: 100%;
    position: relative;
    cursor: pointer;
    border: 1px solid #e4e4e4;
    margin-left: -1px;
}
.bs_field  .tabList .tabMenu li:nth-child(1){margin: 0 0 0 0;}
.bs_field  .tabList .tabMenu li span:hover{
    background: #6188ec;
    color: #fff;
}
.bs_field  .tabList .tabMenu li span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    color: #666;
    transition: 0.3s;
    font-weight: 400;
    height: 100%;
    padding: 0.875rem 0.25rem;
}
.bs_field  .tabList .tabMenu li.on span {
    color: #ffffff;
    font-weight: 600;
    background: #1e46b5;
}
.bs_field .tabList #tabContent {
    margin: 0 auto 0;
    width: 100%;
    overflow: hidden;
    background: #fff;
    padding: 0% 0 3%;
}

.bs_field .tabList #tabContent  article {
    width: 100%;
    margin: 0 auto;
    display: none;
}

.bs_field .tabList #tabContent .r_title{
    font-size: 16px;
    margin: 0 0 25px;
    font-weight: 400;
}
.bs_field .tabList #tabContent .r_title span{
    font-size: 20px;
    vertical-align: baseline;
    font-weight: 700;
    margin: 0 5px 0 0px;
}
/***탭 콘텐츠*****/


/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	
.in_cont .co_box .w02 .tg01 .t02{
    font-size: 1rem;
}
}

@media screen and (max-width:1320px){	
 .in_cont.col2{
    flex-direction: column;
}
	 .in_cont.col2 .co_box{
    width: 100%;
}
	d .in_cont .co_box .w02 .tg01 .t02{
    font-size: 1.2rem;
}
}

@media screen and (max-width:1190px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){
 .circle{
	width: 170px;
	height: 170px;
}
 .circle::before{
    margin-left: -85px;
    margin-top: -85px;
}
.circle img{
	width: 130px;
	height: 130px;
	margin: 20px auto 0;
}
}
 
@media screen and (max-width:920px){

} 

 
@media screen and (max-width:768px){
	.bs_field .titg01 h2{
    font-size: 1.65rem;
}
.bs_field .titg01 p.t01{
    font-size: 1rem;
}
	.in_cont .co_box .w02 .tg01 .t01{
    margin: 0 0 1.5rem;
    font-size: 1.45rem;
}
	.in_cont .co_box .w02 .tg01 .t01 p{
    font-size: 1.15rem;
}
	 .in_cont .co_box .w02 .tg01 .t02{
    font-size: 1rem;
}
}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){
 .in_cont .co_box .w01 .circle{
	width: 125px;
	height: 125px;
}
.in_cont .co_box .w01 .circle::before{
    margin-left: -62px;
    margin-top: -60px;
}
 .in_cont .co_box .w01 .circle img{
	width: 100px;
	height: 100px;
	margin: 15px auto 0;
}
}

@media screen and (max-width:479px){

}

/*//////************사업분야 끝***************/


/*************사업영역 시작***************/
.bs_area .content01{
     background: #000;
     background-size: cover;
     padding: 3.5rem 0 5%;
     position: relative;
     height: 400px;
}
.bs_area .content01 .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.9;
}

  .bs_area .content01 .bg-video .bg-video__content {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.75;
     background-size: cover;	 
  }

.bs_area .content01 .title{
    color: #fff;
    font-size: 2.725rem;
    text-align: center;
    margin: 0 0 4rem;
    z-index: 2;
    position: relative;
}
.bs_area .content01 .title p{
    font-size: 1.25rem;
    text-align: center;
    z-index: 2;
    position: relative;
    width: 80%;
    margin: 1.5% auto 0;
    padding: 3% 1.25rem;
    background: rgb(0 0 0 / 25%);
    border-radius: 1.25rem;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.bs_area .content02{
    padding: 6% 0 2%;
}
.bs_area .content02 ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 calc(10% - 30px) 0;
    justify-content: space-between;
}
.bs_area .content02 .tg01{width: calc(40% - 30px);margin: 0 5% 0 0px;text-align: right;}
.bs_area .content02 .tg01 .tno{
    font-size: 2rem;
    font-weight: 700;
    color: #3f72d1;
}

.bs_area .content02 .tg01 .t01{
    font-size: 2.75rem;
    font-weight: 700;
}

.bs_area .content02 .tg01 .t02{
    font-size: 1.255rem;
    line-height: 1.8;
    color: #444;
}
.bs_area .content02 .tg01 .t02 img{
    max-width: 22px;
    vertical-align: middle;
    margin: 0px 6px 0 0px;
}
.bs_area .content02 .fg01{width: 48%;}
.bs_area .content02 .fg01 img{
	box-shadow: 30px 30px 45px rgba(0, 0, 0, .15);
	border-radius: 1.25rem;
}
.bs_area .content02 li.row02{
    flex-direction: row-reverse;
}
.bs_area .content02 .row02 .tg01{
    margin: 0 0 0 5%;
    text-align: left;
}
.bs_area .content02 .line{
	height: 0;
	border-bottom: 3px solid #3972ed;
	width: 2.75rem;
	margin: 0.875rem 0 1.35rem auto;
}
.bs_area .content02 .row02 .line{
	margin: 0.875rem auto 1.35rem 0;
}

/* 반응형  css  base pc ---> mobile */
@media screen and (max-width:1320px){	
.bs_area .content02 .tg01 .t02{
}
}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){
.bs_area .content02 ul li{
    display: flex;
    flex-direction: column;
    margin: calc(10% - 10px) 0 0 0;
}
	.bs_area .content02 li.row02{
    flex-direction: column;
}
	.bs_area .content02 .tg01{
    width: 100%;
    margin: 0 0 1rem;
    text-align: center;
}
	.bs_area .content02 .fg01{
    width: 100%;
    text-align: center;
}
	.bs_area .content02 .line{margin: 0.875rem auto 1.35rem;}
	.bs_area .content02 .row02 .tg01{
    text-align: center;
    margin: 0 0 1rem;
}
	.bs_area .content02 .row02 .line{
    margin: 0.875rem auto 1.35rem;
}
}
 
@media screen and (max-width:768px){
	.bs_area .content01{
    height: 300px;
}
.bs_area .content02{
    padding: 1rem 0 4.875rem;
}


.bs_area .content02 .tg01 .t02{
}


	.bs_area .content02 li.row02 .tg01{
    margin: 0 0 1rem 0;
}
}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}

/*//////************사업영역 끝***************/



/*************파트너사 시작***************/
.bs_partner .content01{
    background: url(../img/business/partner_bg01.jpg) center no-repeat;
    background-size: cover;
    padding: 4rem 0rem 4rem;
}
.bs_partner .content01 .title_wg01{
    color: #fff;
    text-align: center;
}
.bs_partner .content01 .title_wg01 .tit_w01{
    color: #4a7dff;
    font-size: 1.875rem;
    font-weight: 800;
    font-family: 'Play';
    margin: 0 0 15px;
}
.bs_partner .content01 .title_wg01 .tit_w02{
    font-size: 1.5rem;
}
.bs_partner .content01 .title_wg01 .line{
    width: 1px;
    height: 70px;
    background: #fff;
    margin: 25px auto 30px;
    position: relative;
}
.bs_partner .content01 .title_wg01 .line:after{
    width: 11px;
    height: 11px;
    background: #fc7e2f;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -5px;
    left: -5px;
    border-radius: 2px;
    z-index: 0;
    transform: rotate(45deg);
}

.bs_partner .content01 .title_wg02{
    color: #fff;
    text-align: center;
}
.bs_partner .content01 .title_wg02 .title_w01{
    font-size: 2.325rem;
}
.bs_partner .content01 .title_wg02 .title_w01 strong{}


.bs_partner .content02{
    background: #f0f2f4;
    padding: 2% 0px 6%;
}
.bs_partner .content02 .gallery_list{
    position: relative;
}
.bs_partner .content02 .gallery_list .g_tit{
    
margin: 2rem 0 1.25rem;
}
.bs_partner .content02 .gallery_list .g_tit h2{
    
font-size: 2rem;
}
.bs_partner .content02 .gallery_list .g_tit p{
    
font-size: 1.25rem;
    
color: #666;
}
.bs_partner .content02 .gallery_list ul{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.25rem;
    justify-content: space-between;
}
.bs_partner .content02 .gallery_list ul li{
    transition: all 0.3s;
    position: relative;
    box-sizing: border-box;
    width: calc((100% - 5%)/5);
}
.bs_partner .content02 .gallery_list ul li .pic{
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 100%;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    padding: 1rem 0.5rem;
    transition: 0.3s;
}
.bs_partner .content02 .gallery_list ul li .pic:hover{
    box-shadow: 0 22px 22px rgb(0 0 0 / 8%);
    transform: translate(0px, -4px);
}
.bs_partner .content02 .gallery_list ul li .pic img{
}
/* 반응형  css  base pc ---> mobile */



@media screen and (max-width:992px){

    
.bs_partner .content02 .gallery_list ul{
   row-gap: 15px;
}
    
    .bs_partner .content02 .gallery_list ul li{
    width: calc((100% - 2%)/2);
}

    .bs_partner .content02 .gallery_list ul li .pic{
}
    .bs_partner .content01 .title_wg02 .title_w01{
    font-size: 1.725rem;
}
}
 
@media screen and (max-width:768px){

}

@media screen and (max-width:690px) {
.bs_partner .content01 .title_wg01 .tit_w01{
    font-size: 1.6rem;
    margin: 2px 0;
}
    .bs_partner .content01 .title_wg01 .tit_w02{
        font-size: 1.15rem;
    }

.bs_partner .content01 .title_wg01 .line{
    height: 40px;
    margin: 15px auto 20px;
}
    .bs_partner .content01 .title_wg01 .line:after{
    width: 7px;
    height: 7px;
    left: -3px;
}
    .bs_partner .content01 .title_wg02 .title_w01{
    font-size: 1.125rem;
}
}

@media screen and (max-width:599px){
.bs_partner .content02{
}
}

@media screen and (max-width:479px){

}


/*////************파트너사 끝***************/





/*************솔루션 공통***************/
.solution .content01 {
    margin: 0 0 6rem;
}
.solution .content02 {
    margin: 0 0 6rem;
}
.solution .content03 {
    margin: 0 0 6rem;
}

.solution .content04 {
   margin: 0 0 6rem;
}

.solution .content05 {
   margin: 0 0 6rem;
}

.solution .content06 {
    margin: 0 0 6rem;
}

.solution .txt_box{
	 font-size: 1.25rem;
	 background: #f9f9f9;
	 padding: calc(2% + 4px) calc(2% + 6px);
	 border: 1px solid #e7e7e7;
	 border-radius: 0.75rem;
	 color: #555;
}

.solution .cont_box{
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 margin: calc(4% - 0px) 0 0 0;
	 justify-content: space-between;
	 gap: 2%;
}
.solution .cont_box .tg01{
	width: calc((100% - 0%)/ 2);
}
.solution .cont_box .tg01 .t01{
	font-size: 2.75rem;
	font-weight: 700;
	font-family: 'play', "ONE-Mobile";
	line-height: 1.2;
}
.solution .cont_box .tg01 .t01 span{color: #365dcc;}
.solution .cont_box .tg01 .t02{
	font-size: 1.255rem;
	line-height: 2;
	font-weight: 400;
	color: #444;
	text-align: left;
}
.solution .cont_box .tg01 .t02 img{
	max-width: 22px;
	vertical-align: middle;
	margin: 0px 6px 0 0px;
}
.solution .cont_box .fg01{
	width: calc((100% - 10% ) / 2);
	text-align: center;
}
.solution .cont_box .fg01 img{}

.solution .cont_box .line{
	height: 0;
	border-bottom: 3px solid #3972ed;
	width: 2.75rem;
	margin: 0.875rem auto 1.35rem 0;
}


/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	
.solution .cont_box .tg01 .t02{
    font-size: 1rem;
}

}

@media screen and (max-width:1190px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){
.solution .cont_box{
	 display: flex;
    flex-direction: column;
    margin: calc(10% - 10px) 0 0 0;
}
.solution .cont_box .tg01{
    width: 100%;
    margin: 0 0 1rem;
    text-align: center;
}
.solution .cont_box .fg01{
    width: 65%;
    text-align: center;
}
.solution .cont_box .line{
margin: 0.875rem auto 1.35rem;
}
}
@media screen and (max-width:920px){

} 

 
@media screen and (max-width:768px){
.solution .txt_box{
    font-size: 1rem;
}
}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}

/*////************솔루션 공통 끝***************/


/************그룹웨어 시작*************/

/***탭 콘텐츠*****/
.tabList  {position: relative;}
.group  .tabList > nav {
    max-width: 1100px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto 2%;
}
.group  .tabList > nav.fixed{
    position: fixed;
    top: 7.5%;
    z-index: 999;
    box-shadow: 0 15px 20px rgb(0 0 0 / 10%);
    left: 50%;
    max-width: 100%;
}

.group   .tabList .tabMenu {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0;
    display: flex;
}
.group  .tabList .tabMenu li {
    width: 50%;
    height: 100%;
    position: relative;
    cursor: pointer;
    border: 1px solid #e4e4e4;
    margin-left: -1px;
}
.group  .tabList .tabMenu li:nth-child(1){margin: 0 0 0 0;}
.group  .tabList .tabMenu li span:hover{
    background: #6188ec;
    color: #fff;
}
.group  .tabList .tabMenu li span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    color: #666;
    transition: 0.3s;
    font-weight: 400;
    height: 100%;
    padding: 0.875rem 0.25rem;
}
.group  .tabList .tabMenu li.on span {
    color: #ffffff;
    font-weight: 600;
    background: #1e46b5;
}
.group  .tabList #tabContent {
    margin: 0 auto 0;
    width: 100%;
    overflow: hidden;
    background: #fff;
    padding: 0% 0 3%;
}

.group  #tabContent  article {
    width: 100%;
    margin: 0 auto;
    display: none;
}

.group .tabList #tabContent .r_title{
    font-size: 16px;
    margin: 0 0 25px;
    font-weight: 400;
}
.group .tabList #tabContent .r_title span{
    font-size: 20px;
    vertical-align: baseline;
    font-weight: 700;
    margin: 0 5px 0 0px;
}
/***탭 콘텐츠*****/
.group .content02 {
    margin: 0 0 3.5rem;
}
.group .content02 .wg01 {
    display: flex;
    background: #f5f7fa;
    border-radius: 1.275rem;
    padding: 3.125rem;
    align-items: center;
    gap: 4%;
    justify-content: space-around;
}
.group .content02 .wg01 .w01{width: 45%;}
.group .content02 .wg01 .w01 .t01{
    font-size: 1.75rem;
    margin: 0 0 1.755rem;
}
.group .content02 .wg01 .w01 .t01 span{
    color: #365dcc;
    font-weight: 500;
}
.group .content02 .wg01 .w01 .t02{}
.group .content02 .wg01 .w01 .t02 ul li{
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    gap: 4px;
    margin: 0 0 0.5rem;
}
.group .content02 .wg01 .w01 .t02 ul li i{color: #2c7bea;line-height: 1;}
.group .content02 .wg01 .w02{
    width: 40%;
}
.group .content02 .wg01 .w02 .pic_box{
	position: relative;
	width: 230px;
	height: 230px;
	z-index: 10;
	margin: 0 auto;
	animation: updown 1.8s infinite;
}
@keyframes updown {
	0% {transform: translate(0px, 0px)}	
	50%  {transform: translate(0px, 10px) }
	100%  {transform: translate(0px, 
								10x) }
}
.group .content02 .wg01 .w02 .pic_box:after {content:'';width: 100%;height: 100%;background: #267fe4;border-radius: 0.75rem;transform:rotate(45deg);position:absolute;top: 0px;left: 0;z-index:-1;}
	
.group .content02 .wg01 .w02 .pic_box:before {content:'';width: 90%;height: 90%;border: 1px solid rgba(255,255,255,0.4);border-radius: 0.5rem;position:absolute;top: 11px;left: 11px;transform:rotate(45deg);}



.group .content02 .wg01 .w02 .pic_box img{
	  position: relative;
	  top: 50%;
	  transform: translateY(-50%);
	  display: block;
	  margin: 0 auto;
	  max-width: 120px;
}
  
  
  /* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	


}

@media screen and (max-width:1190px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:920px){
.group .content02 .wg01{
    flex-direction: column;
    row-gap: 2rem;
}
	.group .content02 .wg01 .w01{
    width: 100%;
    order: 2;
}
	.group .content02 .wg01 .w02{
    width: 100%;
    order: 1;
}
	.group .content02 .wg01 .w02 .pic_box{
    width: 200px;
    height: 200px;
}
	.group .content02 .wg01 .w02 .pic_box:before{top: 9px;left: 9px;}
	.group .content02 .wg01 .w02 .pic_box img{
    max-width: 50%;
}
	
} 
@media screen and (max-width:840px){

}
 
@media screen and (max-width:768px){
	.group .content02 .wg01 .w01 .t01{
    font-size: 1.35rem;
}
	.group .content02 .wg01 .w01 .t02 ul li{
    font-size: 1rem;
}
.group .content02 .wg01 .w02 .pic_box{
    width: 100px;
    height: 100px;
}
	.group .content02 .wg01 .w02 .pic_box:before{top: 4px;left: 4px;}
}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}
  
/*/////***********그룹웨어 끝*************/


/************콘텐츠 관리시스템 시작*************/
.contents .content03 {}
.contents .content03 .box_list01{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.contents .content03 .box_list01 .box_item{
    display: flex;
    border-radius: 1rem;
    background: #f9fafa;
    border: 1px solid #e0dfdf;
    overflow: hidden;
    align-items: center;
    gap: 3%;
    padding: 1.25rem 1.25rem;
}
.contents .content03 .box_list01 .box_item .img_box{
    width: 300px;
}
.contents .content03 .box_list01 .box_item .img_box img{
}
.contents .content03 .box_list01 .box_item  .tg01{
    width: calc(100% - 300px);
}
.contents .content03 .box_list01 .box_item  .tg01 .t01{
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 0 0.9rem;
}
.contents .content03 .box_list01 .box_item  .tg01 .t01 p{
    font-size: 1.175rem;
    font-weight: 400;
    color: #4496e0;
}
.contents .content03 .box_list01 .box_item  .tg01 ul.text-list{
}
.contents .content03 .box_list01 .box_item  .tg01 ul.text-list li{font-size: 1.05rem;padding: 0px 0 0 24px;position: relative;margin: 0 0 0.35rem;color: #555;}
.contents .content03 .box_list01 .box_item  .tg01 ul.text-list li:before{
	display:block;
	content:"";
	background: url(../img/solution/ico_check02.png) center no-repeat;
	width: 1.1rem;
	height: 1.1rem;
	position: absolute;
	left: 0;
	top: 0.25rem;
	background-size: contain;
}


.contents .content04 {}
.contents .content04 .menu_wrap{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.contents .content04 .menu_wrap .menu_group {
    width: calc((100% - 5rem) / 5);
 }
.contents .content04 .menu_wrap.col-4 .menu_group{
    width: calc((100% - 5rem) / 4);
}
.contents .content04 .menu_wrap .menu_group .menu_top{
    background: #4e73dc;
    padding: 0.75rem 1rem;
    border-radius: 0.6rem;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0 0 0.6rem;
    text-align: center;
}
.contents .content04 .menu_wrap .menu_group dl{
    padding: 0.45rem 1rem;
    display: flex;
    flex-direction: column;
}
.contents .content04 .menu_wrap .menu_group dl dt{
    font-size: 1.25rem;
    margin: 0 0 0.3rem;
    font-weight: 500;
}
.contents .content04 .menu_wrap .menu_group dl dd{
    font-size: 1.1rem;
    color: #555;
    padding: 0.2rem 0;
}

.contents .content05{}
.contents .content05 .tech_wrap{
    background: #f2f4f7;
    padding: 3rem 0;
}
.contents .content05 .tech_list{}
.contents .content05 .tech_list .tech_w{
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
}
.contents .content05 .tech_list .tech_w .w01{
    width: calc((100% - 1.25rem) / 2 );
    background: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 0.75rem;
    overflow: hidden;
}
.contents .content05 .tech_list .tech_w .w01 .titg{
}
.contents .content05 .tech_list .tech_w .w01 .titg .t01{
    background: #719dd4;
    color: #fff;
    padding: 1.15rem 1.25rem;
    font-size: 1.45rem;
    line-height: 1.2;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
}
.contents .content05 .tech_list .tech_w .w01 .titg .t01 span{
    font-weight: 600;
    font-size: 2rem;
}
.contents .content05 .tech_list .tech_w .w01 .titg .t02{
    padding: 1.25rem 1.55rem;
}
.contents .content05 .tech_list .tech_w .w01 .titg .t02 p{
    color: #555;
    font-size: 1.1rem;
    padding: 0px 0 0 24px;
    position: relative;
    margin: 0 0 0.35rem;
}
.contents .content05 .tech_list .tech_w .w01 .titg .t02 p:before{
	display:block;
	content:"";
	background: url(../img/solution/ico_check02.png) center no-repeat;
	width: 1.1rem;
	height: 1.1rem;
	position: absolute;
	left: 0;
	top: 0.25rem;
	background-size: contain;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	


}

@media screen and (max-width:1190px){	
.contents .content04 .menu_wrap .menu_group{
    width: calc((100% - 2rem) / 3) !important;
}
}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:920px){
.contents .content03 .box_list01 .box_item{
    flex-direction: column;
    row-gap: 20px;
}
	.contents .content03 .box_list01 .box_item .img_box{
    width: 40%;
    text-align: center;
}
	.contents .content03 .box_list01 .box_item .tg01{
    width: 100%;
}
	.contents .content05 .tech_list .tech_w{
    flex-direction: column;
    gap: 1rem;
}
	.contents .content05 .tech_list .tech_w .w01{
    width: 100%;
}
} 
@media screen and (max-width:840px){

}
 
@media screen and (max-width:768px){

}

@media screen and (max-width:690px) {
.contents .content04 .menu_wrap .menu_group dl{
    padding: 0.25rem 1rem;
    display: flex;
    flex-direction: column;
}
.contents .content04 .menu_wrap .menu_group dl dt{
    font-size: 1.15rem;
    margin: 0 0 0.3rem;
}
.contents .content04 .menu_wrap .menu_group dl dd{
    font-size: 1rem;
    padding: 0.1rem 0;
}
}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}


/*/////***********콘텐츠 관리시스템 끝*************/



/************교육 관리시스템 시작*************/

.edu .content03 .box_list01{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.edu .content03 .box_list01 .box_item{
    display: flex;
    border-radius: 1rem;
    background: #f8f9fa;
    border: 1px solid #c9d1e6;
    overflow: hidden;
    align-items: center;
    gap: 3%;
    padding: 1.25rem 1.25rem;
}
.edu .content03 .box_list01 .box_item .img_box{
    width: 240px;
}
.edu .content03 .box_list01 .box_item .img_box img{
}
.edu .content03 .box_list01 .box_item  .tg01{
    width: calc(100% - 300px);
}
.edu .content03 .box_list01 .box_item  .tg01 .t01{
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 0 0.9rem;
}
.edu .content03 .box_list01 .box_item  .tg01 .t01 p{
    font-size: 1.175rem;
    font-weight: 400;
    color: #2554bc;
}
.edu .content03 .box_list01 .box_item  .tg01 ul.text-list{
}
.edu .content03 .box_list01 .box_item  .tg01 ul.text-list li{font-size: 1.05rem;padding: 0px 0 0 24px;position: relative;margin: 0 0 0.35rem;color: #555;}
.edu .content03 .box_list01 .box_item  .tg01 ul.text-list li:before{
	display:block;
	content:"";
	background: url(../img/solution/ico_check02.png) center no-repeat;
	width: 1.1rem;
	height: 1.1rem;
	position: absolute;
	left: 0;
	top: 0.25rem;
	background-size: contain;
}



.edu .content04 .menu_wrap{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.edu .content04 .menu_wrap .menu_group {
    width: calc((100% - 5rem) / 6);
 }
.edu .content04 .menu_wrap.col-4 .menu_group{
    width: calc((100% - 5rem) / 4);
}
.edu .content04 .menu_wrap .menu_group .menu_top{
    background: #4e73dc;
    padding: 0.75rem 1rem;
    border-radius: 0.6rem;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0 0 0.6rem;
    text-align: center;
}
.edu .content04 .menu_wrap .menu_group dl{
    padding: 0.45rem 1rem;
    display: flex;
    flex-direction: column;
}
.edu .content04 .menu_wrap .menu_group dl dt{
    font-size: 1.25rem;
    margin: 0 0 0.3rem;
    font-weight: 500;
}
.edu .content04 .menu_wrap .menu_group dl dd{
    font-size: 1.1rem;
    color: #555;
    padding: 0.2rem 0;
}

.edu .content05{
    background: #f8f8f8;
    padding: 5rem 0 1rem;
}
.txt_img_wrap li{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0 calc(10% - 30px) 0;
    justify-content: space-between;
}
.txt_img_wrap .tg01{width: calc(40% - 30px);margin: 0 5% 0 0px;text-align: right;}
.txt_img_wrap .tg01 .tno{
    font-size: 2rem;
    font-weight: 700;
    color: #3f72d1;
}

.txt_img_wrap .tg01 .t01{
    font-size: 2.75rem;
    font-weight: 700;
}

.txt_img_wrap .tg01 .t02{
    font-size: 1.255rem;
    line-height: 1.8;
    color: #444;
}
.txt_img_wrap .tg01 .t02 img{
    max-width: 22px;
    vertical-align: middle;
    margin: 0px 6px 0 0px;
}
.txt_img_wrap .fg01{width: 48%;}
.txt_img_wrap .fg01 img{
	box-shadow: 30px 30px 45px rgba(0, 0, 0, .15);
	border-radius: 1.25rem;
}
.txt_img_wrap li.row02{
    flex-direction: row-reverse;
}
.txt_img_wrap .row02 .tg01{
    margin: 0 0 0 5%;
    text-align: left;
}
.txt_img_wrap .tg01 .line{
	height: 0;
	border-bottom: 3px solid #3972ed;
	width: 2.75rem;
	margin: 0.875rem 0 1.35rem auto;
}
.txt_img_wrap .row02 .line{
	margin: 0.875rem auto 1.35rem 0;
}


.edu .content06 {}
.edu .content06 .product01{
    display: flex;
    background: #f1f2f6;
    padding: 3rem 2rem;
    border-radius: 1.25rem;
    gap: 3.5rem 2.5rem;
    flex-wrap: wrap;
    align-items: center;
}
.edu .content06 .product01 .w01{
    width: calc((100% - 2.5rem) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edu .content06 .product01 .w01 h2{
    font-size: 1.45rem;
    text-align: center;
    margin: 0 0 1rem;
    background: #2d4472;
    border-radius: 3rem;
    color: #fff;
    padding: 0.4rem 2rem;
}

 .thumb_img01 {
    position: relative;
    text-align: center;
    font-size: 0;
    line-height: 0;
    margin: 0 auto;
}

 .thumb_img01 > .inner {
    position: absolute;
    top: 4.175%;
    left: 3.35%;
    width: 93.3%;
}



/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	


}

@media screen and (max-width:1190px){	
.edu .content04 .menu_wrap .menu_group{
    width: calc((100% - 2rem) / 3) !important;
}
}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){
.edu .content02 ul li{
    display: flex;
    flex-direction: column;
    margin: calc(10% - 10px) 0 0 0;
}
	.edu .content02 li.row02{
    flex-direction: column;
}
	.edu .content02 .tg01{
    width: 100%;
    margin: 0 0 1rem;
    text-align: center;
}
	.edu .content02 .fg01{
    width: 100%;
    text-align: center;
}
	.edu .content02 .line{margin: 0.875rem auto 1.35rem;}
	.edu .content02 .row02 .tg01{
    text-align: center;
    margin: 0 0 1rem;
}
	.edu .content02 .row02 .line{
    margin: 0.875rem auto 1.35rem;
}

.txt_img_wrap li{
    display: flex;
    flex-direction: column;
}
	.txt_img_wrap li.row02{
    flex-direction: column;
}
	.txt_img_wrap .tg01{
    width: 100%;
    margin: 0 0 1rem;
    text-align: center;
}
	.txt_img_wrap .fg01{
    width: 100%;
    text-align: center;
}
	.txt_img_wrap .tg01 .line{margin: 0.875rem auto 1.35rem;}
	.txt_img_wrap .row02 .tg01{
    text-align: center;
    margin: 0 0 1rem;
}
	.txt_img_wrap .row02 .line{
    margin: 0.875rem auto 1.35rem;
}

}
 
@media screen and (max-width:920px){
.edu .content03 .box_list01 .box_item{
    flex-direction: column;
    row-gap: 20px;
}
	.edu .content03 .box_list01 .box_item .img_box{
    width: 40%;
    text-align: center;
}
	.edu .content03 .box_list01 .box_item .tg01{
    width: 100%;
}
} 
@media screen and (max-width:840px){

}
 
@media screen and (max-width:768px){
.edu .content01{
    height: 300px;
}
.edu .content02{
    padding: 1rem 0 4.875rem;
}


.edu .content02 .tg01 .t02{
}


	.edu .content02 li.row02 .tg01{
    margin: 0 0 1rem 0;
}

.edu .content05{
    padding: 1rem 0 4rem;
}


.edu .content05 .tg01 .t02{
}


	.edu .content05 li.row02 .tg01{
    margin: 0 0 1rem 0;
}
	.edu .content06 .product01{
    flex-direction: column;
}
	.edu .content06 .product01 .w01{
    width: calc((85%) / 1);
}
	 .thumb_img01{
}

}

@media screen and (max-width:690px) {
.edu .content04 .menu_wrap .menu_group dl{
    padding: 0.25rem 1rem;
    display: flex;
    flex-direction: column;
}
.edu .content04 .menu_wrap .menu_group dl dt{
    font-size: 1.15rem;
    margin: 0 0 0.3rem;
}
.edu .content04 .menu_wrap .menu_group dl dd{
    font-size: 1rem;
    padding: 0.1rem 0;
}
}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}

/*/////***********교육 관리시스템 끝*************/


/************물류 관리시스템 시작*************/
.dist .content02{
	    background: #f8f8f8;
    padding: 5rem 0 1rem;
}

.flow {font-size:0;padding: 3rem 0 5rem;}
.flow dl {position:relative;display:inline-block;width: 16.6%;text-align:center;vertical-align:top;}
.flow dl:after {content:'';width: 30px;height: 30px;background: url('../img/solution/flow_bullet01.png')no-repeat center; position:absolute;top: 50px;right: -15px;background-size:100%;}
.flow dl:last-child:after {display:none;}
.flow dl dt {position:relative; width:130px; height:130px; z-index:10; margin:0 auto;}
.flow dl dt img {position:relative;top:50%;transform:translateY(-50%);display:block;margin:0 auto;max-width: 55px;}
.flow dl dt:after {content:''; width:95px; height:95px; background:#1f88ff; border-radius:10px; transform:rotate(45deg); position:absolute; top:17px; left:18px; z-index:-1;}
.flow dl dt:before {content:''; width:80px; height:80px; border:1px solid rgba(255,255,255,0.25); border-radius:5px; position:absolute; top:23px; left:25px; transform:rotate(45deg);}
.flow dl dd {font-size:1.333rem;color: #224085;line-height:1;letter-spacing:-0.6pt;margin:30px 0 0;font-weight: 500;}
.flow dl dd p{
    font-size: 1.075rem;
    color: #555;
    line-height: 1.3;
    font-weight: 400;
    margin: 0.75rem 0 0;
}

.flow dl.two dt:after {background:#1676e3;}
.flow dl.three dt:after {background:#0f68cd;}
.flow dl.four dt:after {background:#0a5dbc;}
.flow dl.five dt:after {background:#0958b5;}
.flow dl.six dt:after {background:#084d9f;}


.dist .content03{}


.dist .content04{}
.dist .content04 .img_box01{
    text-align: center;
    box-shadow: 0px 10px 25px 0px rgb(0 0 0 / 15%);
    padding: 5% 5%;
    border-radius: 1.5rem;
    border: 2px dashed #9ca7d2;
}

.dist .content05 .box_list01{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.dist .content05 .box_list01 .box_item{
    display: flex;
    border-radius: 1rem;
    background: #f6f7f9;
    border-bottom: 5px solid #c6c6dc;
    overflow: hidden;
    align-items: center;
    gap: 6%;
    padding: 2rem 3rem;
}
.dist .content05 .box_list01 .box_item .img_box{
    width: 300px;
}
.dist .content05 .box_list01 .box_item .img_box img{
}
.dist .content05 .box_list01 .box_item  .tg01{
    width: calc(100% - 300px);
    display: flex;
    gap: 2rem;
    align-items: center;
}
.dist .content05 .box_list01 .box_item  .tg01 .t01{
    font-size: 2.2rem;
    font-weight: 500;
    width: 260px;
    color: #223344;
}
.dist .content03 .box_list01 .box_item  .tg01 .t01 p{
    font-size: 1.175rem;
    font-weight: 400;
    color: #4496e0;
}
.dist .content05 .box_list01 .box_item  .tg01 ul.text-list{
}
.dist .content05 .box_list01 .box_item  .tg01 ul.text-list li{font-size: 1.1rem;padding: 0px 0 0 24px;position: relative;margin: 0 0 0.35rem;color: #555;}
.dist .content05 .box_list01 .box_item  .tg01 ul.text-list li:before{
	display:block;
	content:"";
	background: url(../img/solution/ico_check02.png) center no-repeat;
	width: 1.1rem;
	height: 1.1rem;
	position: absolute;
	left: 0;
	top: 0.25rem;
	background-size: contain;
}



/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	


}

@media screen and (max-width:1190px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:920px){
.flow dl{
    width: 33%;
}
	.flow dl:nth-child(-n+3){
		margin-bottom: 2rem;
	}
	.flow dl dt{
    width: 100px;
    height: 100px;
}
	.flow dl dt:before{
    width: 60px;
    height: 60px;
    top: 19px;
    left: 19px;
}
	.flow dl dt:after{
    width: 74px;
    height: 74px;
    top: 13px;
    left: 13px;
}
	.flow dl dt img{
    max-width: 40px;
}

.dist .content05 .box_list01 .box_item{
    flex-direction: column;
    row-gap: 30px;
    padding: 2rem 2rem;
}
.dist .content05 .box_list01 .box_item .img_box{
    width: 40%;
    text-align: center;
}
.dist .content05 .box_list01 .box_item .tg01{
    width: 100%;
    gap: 0.75rem;
}


} 
@media screen and (max-width:840px){

}
 
@media screen and (max-width:768px){
.dist .content05 .box_list01 .box_item .tg01{
    flex-direction: column;
    align-items: flex-start;
}
}

@media screen and (max-width:690px) {
	.dist .content05 .box_list01 .box_item .tg01 .t01{
    font-size: 1.4rem;
    width: auto;
}
}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}

/*/////***********물류 관리시스템 끝*************/


/************의료블록체인 시작*************/
.cont_banner{
    background: #000;
    background-size: cover;
    padding: 3rem 0;
    position: relative;
    line-height: 1.5;
    margin: 0 0 6rem;
}
.cont_banner .bg-video {
 position: absolute;
 top: 0;
 left: 0;
 height: 100%;
 width: 100%;
 z-index: 1;
 opacity: 0.9;
}

 .cont_banner .bg-video .bg-video__content {
   height: 100%;
   width: 100%;
   object-fit: cover;
   opacity: 0.6;
   background-size: cover;
 }

.cont_banner .title{
   color: #fff;
   font-size: 2.725rem;
   text-align: center;
   z-index: 2;
   position: relative;
}
.cont_banner .title > div{
      font-size: 1.25rem;
        text-align: center;
       z-index: 2;
       position: relative;
       width: 96%;
       margin: 1.5% auto 0;
       padding: 3% 1.25rem;
       background: rgb(0 0 0 / 25%);
       border-radius: 1.25rem;
       -webkit-backdrop-filter: blur(5px);
       backdrop-filter: blur(5px);
}
.cont_banner .title p.tit{
   font-size: 1.75rem;
   font-weight: 500;
   color: #95c9ff;
   margin: 0 0 0.8rem;
}
.cont_banner .title img{
   width: 70px;
   margin: 0 0 0.75rem;
}
.cont_banner .title p.txt{
font-weight: 300;
}

.blockchain .content02{
   background: #f8f8f8;
   padding: 5rem 0 1rem;
}


.cbox_w01{
   text-align: center;
   position: relative;
   padding: 3rem 2rem;
   background: #eff0f9;
   border-radius: 2rem;
}
.cbox_w01 ul{
   display: flex;
   gap: 3%;
   flex-wrap: wrap;
   justify-content: space-evenly;
}
.cbox_w01 ul li{
   width: 30%;
}
.cbox_w01 ul li .cb01{
   padding: 2rem 2rem;
   position: relative;
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px);
   border-radius: 1rem;
   min-height: 260px;
   box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
   background: #ffffff;
}
.cbox_w01 ul li .cb01 .tg01{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   row-gap: 0.85rem;
}
.cbox_w01 ul li .cb01 .tg01 figure{
   width: 60px;
   margin: 0 0 0.75rem;
}
.cbox_w01 ul li .cb01 .tg01 figure img{}
.cbox_w01 ul li .cb01 .tg01 .t01{
   text-align: center;
   font-size: 1.525rem;
}
.cbox_w01 ul li .cb01 .tg01 .t02{
   text-align: center;
   font-size: 1.125rem;
   line-height: 1.45;
   color: #555;
}


.blockchain .content04{
   background: url('../img/solution/blockchain_bg.jpg') center no-repeat;
   padding: 5rem 2rem;
   background-size: cover;
}
.blockchain .content04 .wg01{
   margin: 0 0 6rem;
}
.blockchain .content04 .t_box{
   color: #fff;
   font-size: 2rem;
}
.blockchain .content04 .wg01 .t_box .title{
   font-size: 1.45em;
   font-weight: 500;
   margin: 0 0 0.75rem;
   line-height: 1.3;
}
.blockchain .content04 .wg01 .t_box .txt{
   font-size: 0.7em;
}

.blockchain .content04 .wg02{
}

.blockchain .content04 .wg02 .t_box .title{
   font-size: 1.1em;
   font-weight: 500;
   margin: 0 0 0.75rem;
}
.blockchain .content04 .wg02 .t_box .txt{
   font-size: 0.65em;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	
.cbox_w01 ul{
   row-gap: 1rem;
   flex-direction: column;
}
.cbox_w01 ul li{
   width: 100%;
}
.cbox_w01 ul li .cb01{
   min-height: auto;
}

}

@media screen and (max-width:1190px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}

@media screen and (max-width:920px){

} 
@media screen and (max-width:840px){

}

@media screen and (max-width:768px){
.cont_banner{
}
   .blockchain .content04{
   background-position: 76%;
}
   .blockchain .content04 .t_box{
   font-size: 1.75rem;
}
}

@media screen and (max-width:690px) {
.cont_banner .title{
       font-size: 1.525rem;
}
   .cont_banner .title img{
   width: 40px;
}
.cont_banner .title p{
   font-size: 1.1rem;
}
   .cont_banner .title p.tit{
   font-size: 1.25rem;
}
}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}
/*/////***********의료블록체인 끝*************/




/************채용정보*************/
.recruit .content01{
    background: url(../img/recruit/recruit_bg01.jpg) center no-repeat fixed;
    background-size: cover;
    padding: 5rem 0rem 5rem;
    margin: 0 0 6rem;
}
.recruit .content01 .title{
    color: #fff;
    font-size: 2.725rem;
    text-align: center;
    margin: 0 0 4rem;
    font-weight: 500;
}
.recruit .content01 .title p{
    font-size: 1.45rem;
    font-weight: 300;
    line-height: 1.4;
    margin: 1.25rem 0 0;
}
.recruit .content01 .cbox_w{}
.recruit .content01 .cbox_w ul{
    display: flex;
    justify-content: center;
    gap: 3%;
    flex-wrap: wrap;
}
.recruit .content01 .cbox_w ul li{
    width: 30%;
}
.recruit .content01 .cbox_w ul li .cb01{
    padding: 2rem 2rem;
    position: relative;
    background: rgb(0 0 0 / 30%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 1rem;
    min-height: 260px;
}
.recruit .content01 .cbox_w ul li .cb01 .tg01{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.85rem;
    color: #fff;
}
.recruit .content01 .cbox_w ul li .cb01 .tg01 figure{
    width: 60px;
    margin: 0 0 0.75rem;
}
.recruit .content01 .cbox_w ul li .cb01 .tg01 figure img{}
.recruit .content01 .cbox_w ul li .cb01 .tg01 .t01{
    text-align: center;
    font-size: 1.525rem;
}
.recruit .content01 .cbox_w ul li .cb01 .tg01 .t02{
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.45;
    color: #f8f8f8;
}


/* 채용절차 */
.recruit .content02{margin: 0 0 6rem;}
	.recruit .recruit_process {
    background: #eff0f9;
    border-radius: 2rem;
}
	.recruit  .recruit_processList {text-align:center;position:relative;display:flex;flex-wrap:wrap;padding: 80px 40px;}	
	.recruit .recruit_processList > div {
        position: relative;
        width: calc((100% - 240px) / 5);
        margin-right: 60px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        background: #fff;
        box-shadow: 0 20px 30px rgba(0,0,0,0.05);
        border-radius: 100%;
        padding: 50.25px 0;
     }
	.recruit  .recruit_processList > div:last-child {margin:0}
	.recruit  .recruit_processList dl {}	
	.recruit  .recruit_processList dt {color:#333;font-weight:700;line-height:1;letter-spacing:-0.3pt;font-size: 1.45rem;margin-bottom:20px;}	
	.recruit  .recruit_processList dt span {line-height:1;font-size: 1.1rem;display:block;margin-bottom: 12px;letter-spacing: 0.4pt;font-weight: 500;color: #777;}	
	.recruit  .recruit_processList dd {}	
	.recruit  .recruit_processList dd img {}	

	.recruit  .recruit_processList > div:last-child {background:#1f25a3}
	.recruit  .recruit_processList > div:last-child dt {color:#fff;}

	.recruit  .recruit_processList .arrow {position:absolute; top:50%; right:-45px; transform:translate(0, -50%)}
	.recruit  .recruit_processList .arrow .m_scroll_arrows {width:8px;height:12px;background:  url('../img/recruit/recruit_arrow.png') center center/100% no-repeat;opacity:1;float:left;margin-right:3px}
	.recruit  .recruit_processList .arrow .m_scroll_arrows:last-child {margin-right:0}
	.recruit  .recruit_processList .arrow .doi {opacity:0.6}
	.recruit  .recruit_processList .arrow .trei {opacity:0.3}

	.unu, .doi, .trei {-webkit-animation: mouse-scroll 1s infinite; -moz-animation: mouse-scroll 1s infinite; animation: mouse-scroll 1s infinite;}
	.unu {-webkit-animation-delay: .1s; -moz-animation-delay: .1s; -webkit-animation-direction: alternate; animation-direction: alternate; animation-delay: alternate;}
	.doi {-webkit-animation-delay: .2s; -moz-animation-delay: .2s; -webkit-animation-direction: alternate; animation-delay: .2s; animation-direction: alternate;}
	.trei {-webkit-animation-delay: .3s; -moz-animation-delay: .3s; -webkit-animation-direction: alternate; animation-delay: .3s; animation-direction: alternate;}

	@-webkit-keyframes mouse-scroll {
		0%   { opacity: 0;}
		50%  { opacity: .5;}
		100% { opacity: 1;}
	}
	@-moz-keyframes mouse-scroll {
		0%   { opacity: 0; }
		50%  { opacity: .5; }
		100% { opacity: 1; }
	}
	@-o-keyframes mouse-scroll {
		0%   { opacity: 0; }
		50%  { opacity: .5; }
		100% { opacity: 1; }
	}
	@keyframes mouse-scroll {
		0%   { opacity: 0; }
		50%  { opacity: .5; }
		100% { opacity: 1; }
	}

/* 인사제도 */

.recruit .content03{
    margin: 0 0 6rem;
}
.recruit .content03 .box_list01{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.recruit .content03 .box_list01 .box_item{
    display: flex;
    border-radius: 1rem;
    background: #f6f7f9;
    border: 1px solid #dddde4;
    overflow: hidden;
    align-items: center;
    gap: 4%;
    padding: 2rem 3rem;
}
.recruit .content03 .box_list01 .box_item .img_box{
    width: 300px;
    text-align: center;
}
.recruit .content03 .box_list01 .box_item .img_box img{width: 100px;}
.recruit .content03 .box_list01 .box_item  .tg01{
    width: calc(100% - 300px);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
}
.recruit .content03 .box_list01 .box_item  .tg01 .t01{
    font-size: 1.75rem;
    font-weight: 500;
    color: #223344;
}
.recruit .content03 .box_list01 .box_item  .tg01 .t01 p{
    font-size: 1.175rem;
    font-weight: 400;
    color: #4496e0;
}
.recruit .content03 .box_list01 .box_item  .tg01 ul.text-list{
}
.recruit .content03 .box_list01 .box_item  .tg01 ul.text-list li{font-size: 1.2rem;padding: 0px 0 0 24px;position: relative;margin: 0 0 0.35rem;color: #555;}
.recruit .content03 .box_list01 .box_item  .tg01 ul.text-list li:before{
	display:block;
	content:"";
	background: url(../img/solution/ico_check02.png) center no-repeat;
	width: 1.1rem;
	height: 1.1rem;
	position: absolute;
	left: 0;
	top: 0.25rem;
	background-size: contain;
}
.recruit .content03 .box_list02{}
.recruit .content03 .box_list02 ul{
    display: flex;
    gap: 1.5rem;
}
.recruit .content03 .box_list02 ul li {
    width: calc((100% - 0px) / 4);
    box-shadow: 0 20px 22px rgb(0 0 0 / 6%);
    border-radius: 1rem;
    border: 1px solid #cecece;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.25rem;
    padding: 1.25rem 1.25rem 1.45rem;
 }
.recruit .content03 .box_list02 ul li figure{
    width: 130px;
    height: 130px;
    background: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recruit .content03 .box_list02 ul li figure img{
    width: 75px;
}
.recruit .content03 .box_list02 ul li .txt p{
    font-size: 1.25rem;
    text-align: center;
    line-height: 1.4;
}

/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
	.recruit .recruit_processList > div{
    border-radius: 30px;
}
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	
.recruit .content01 .cbox_w ul{
    row-gap: 1rem;
    flex-direction: column;
}
	.recruit .content01 .cbox_w ul li{
    width: 100%;
}
	.recruit .content01 .cbox_w ul li .cb01{
    min-height: auto;
}
}

@media screen and (max-width:1090px){
.recruit .content03 .box_list02 ul{
    flex-wrap: wrap;
}
.recruit .content03 .box_list02 ul li{
    width: calc((100% - 1.5rem) / 2);
}
}

@media screen and (max-width:992px){

.recruit .content01 .title{
    font-size: 1.8rem;
}
	.recruit .content01 .title p{font-size: 1.2rem;}
    .recruit .content01 .cbox_w ul li .cb01 .tg01 figure{
}
.recruit .content01 .cbox_w ul li .cb01 .tg01 .t01{
    font-size: 1rem;
}


	.recruit  .recruit_processList {padding: 70px 20px;}	
	.recruit  .recruit_processList > div {margin-right:40px; width:calc((100% - 160px)/5); padding:30px 0; border-radius:20px}	
	.recruit  .recruit_processList dl {}	
	.recruit  .recruit_processList dt {margin-bottom:15px}	
	.recruit  .recruit_processList dt span {}	
	.recruit  .recruit_processList dd {margin:0 auto; width:50px}	
	.recruit  .recruit_processList dd img {width:100%}	

	.recruit  .recruit_processList .arrow {right:-30px}
	.recruit  .recruit_processList .arrow .m_scroll_arrows {width:5px; height:8px; margin-right:1px}
	

	.recruit  .recruitList {padding-top:70px}
	.recruit  .recruitList dl {margin-bottom:30px}


}


@media screen and (max-width:920px){
.recruit .content03 .box_list01 .box_item{
    flex-direction: column;
    row-gap: 30px;
    padding: 2rem 2rem;
}
.recruit .content03 .box_list01 .box_item .img_box{
    width: 40%;
    text-align: center;
}
.recruit .content03 .box_list01 .box_item .tg01{
    width: 100%;
    gap: 0.75rem;
}

} 

@media screen and (max-width:840px){
.recruit  .recruit_processList {padding: 30px 15px;}	
	.recruit  .recruit_processList > div {margin:0 0 40px; width:calc((100% - 10px)/2); padding:20px 0; border-radius:10px; margin:0 10px 40px 0}	
	.recruit  .recruit_processList > div:nth-child(2n) {margin-right:0}
	.recruit  .recruit_processList > div:last-child {width:100%}
 	.recruit  .recruit_processList dl {}	
	.recruit  .recruit_processList dt {margin-bottom:10px}	
	.recruit  .recruit_processList dt span {margin-bottom:5px}	
	.recruit  .recruit_processList dd {width:40px}	

	.recruit  .recruit_processList > div:nth-child(2n) .arrow {display:none;}
	.recruit  .recruit_processList .arrow {right:-13px; transform:rotate(90deg); top:auto; bottom:-24px; margin-right:0}
	.recruit  .recruit_processList .arrow .m_scroll_arrows {width:5px; height:8px; margin-right:1px}
	

	.recruit  .recruitList {padding-top:60px}
	.recruit  .recruitList dl {margin-bottom:20px}
	.recruit  .recruitList dt {padding:12px 15px; width:100px;}
	.recruit  .recruitList dt span {width:25px; height:25px; line-height:25px; left:15px; top:-12px}
	.recruit  .recruitList dd {padding:12px 15px; width:calc(100% - 100px)}
}
 
@media screen and (max-width:768px){

    .recruit .content01{
    padding: 7% 0rem 8%;
}
.recruit .content01 .cbox_w ul{
}
.recruit .content01 .cbox_w ul li{
}
     .recruit .content01 .cbox_w ul li .cb01 .tg01 .t01{
    font-size: 3.4vw;
    }
	
       .recruit .content03 .box_list01 .box_item .tg01{
    flex-direction: column;
    align-items: flex-start;
}
.recruit .content03 .box_list02 ul li figure{
    width: 110px;
    height: 110px;
}
	.recruit .content03 .box_list02 ul li figure img{
    width: 60px;
}
}




@media screen and (max-width:690px) {
 .recruit .content03 .box_list01 .box_item .tg01 .t01{
    font-size: 1.4rem;
    width: auto;
}


}


@media screen and (max-width:599px){


@media screen and (max-width:479px){

}

/*/////***********채용정보 끝*************/




/* 반응형  css  base pc ---> mobile */
@media all and (max-width:1600px) { 
}

@media screen and (max-width:1440px){	

}

@media screen and (max-width:1320px){	


}

@media screen and (max-width:1190px){	

}

@media screen and (max-width:1090px){

}

@media screen and (max-width:992px){

}
 
@media screen and (max-width:920px){

} 
@media screen and (max-width:840px){

}
 
@media screen and (max-width:768px){

}

@media screen and (max-width:690px) {

}


@media screen and (max-width:599px){

}

@media screen and (max-width:479px){

}
}