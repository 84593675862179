@charset "utf-8";

/****프리텐다드 웹폰트****/
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');


/****poppins 웹폰트****/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


/****play 웹폰트****/
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

/****web ico 폰트****/
@import url('https://cdn-uicons.flaticon.com/2.6.0/uicons-regular-rounded/css/uicons-regular-rounded.css');


/****원스토어 모바일고딕 제목체****/
@font-face {
    font-family: 'ONE-Mobile-Title';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-Title.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/****원스토어 모바일고딕 본문체****/
@font-face {
    font-family: 'ONE-Mobile';
    src: url('fonts/ONE Mobile OTF Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ONE-Mobile';
    src: url('fonts/ONE Mobile OTF Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ONE-Mobile';
    src: url('fonts/ONE Mobile OTF Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/****여기어때 잘난체 고딕****/
@font-face {
    font-family: 'JalnanGothic';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}